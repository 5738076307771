import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const GenericSelect = ({ label, value, onChange, options, disabledCondition = () => false, formControlWidth }) => {
  return (
    <FormControl sx={{ width: formControlWidth || "50%" }}>
      <InputLabel id={`${label}-label`} sx={{ "&.MuiInputLabel-shrink": { padding: "0 5px", background: "white" } }}>
        {label}
      </InputLabel>
      <Select labelId={`${label}-label`} id={`${label}-select`} value={value} onChange={onChange}>
        {options.map((item) => (
          item && <MenuItem disabled={disabledCondition(item)} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenericSelect;