import "./index.css";

import { ThemeProvider, makeStyles } from "@mui/material/styles";

import App from "./App";
import { AppProvider } from "./utils/app-context";
import { AuthProvider } from "./utils/auth-provider";
import React from "react";
import ReactDOM from "react-dom";
// import CssBaseline from '@material-ui/core/CssBaseline';
// import "fontsource-roboto/latin-500-normal.css"
// import "fontsource-roboto/latin-700-normal.css"
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import reportWebVitals from "./reportWebVitals";
import theme from "./styles/theme";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <AppProvider>
          {" "}
          {/* Top level app context provider */}
          <ThemeProvider theme={theme}>
            <App />
            {/* Toast */}
            <Toaster
              position="bottom-center"
              toastOptions={{
                duration: 4000,
                className: 'toaster',
                style: {
                  margin: '72px 10px',
                  zIndex:'99'
               },
                success: {
                  iconTheme: {
                    primary: "green",
                    secondary: "white",
                  },
                },
              }}
            />
          </ThemeProvider>
        </AppProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//         <ThemeProvider theme={theme}>
//        </ThemeProvider>,
//            <CssBaseline /> {/* MasterialUI baseline CSS */}
