import React, { useEffect, useState } from "react";
import firebase, { auth } from "../utils/firebase";
import { onAuthStateChanged, signOut, getAuth } from "@firebase/auth";

export const AuthContext = React.createContext();

export const handleLogout = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        // Redirect to login page or do something else after logout
        window.location.href = "/login";
        console.log('User signed out');
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error signing out:', error);
      });
  };

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setPending(false)
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                pending
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};