import React, { useContext } from "react";
import { Route, useLocation } from "react-router-dom";
import { AuthContext } from "../utils/auth-provider";
import { Redirect } from "react-router-dom";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = useContext(AuthContext);

    const location = useLocation();

    return (
        <Route
            {...rest}
            render={routeProps =>
                !!currentUser ? (
                    <RouteComponent {...routeProps} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: {
                                        pathname: location.pathname,
                                        search: location.search
                                    }
                                }
                            }} />
                    )
            }
        />
    );
};

export default PrivateRoute;