import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const styles = {
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
};

function Loading() {
  return (
    <Box sx={styles.loader}>
      <CircularProgress />
    </Box>
  );
}

export default Loading;
