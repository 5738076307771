import { 
  Box, 
  Button, 
  Drawer, 
  Link, 
  Typography, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  IconButton
} from "@mui/material";
import {
  AutoFixHigh, 
  Close, 
  Settings, 
  Share, 
  SportsScore, 
  Toll, 
  Logout, 
  Star, 
  StarOutline, 
  GridOn, 
  ViewDay
} from "@mui/icons-material";
import React, { useContext, useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AuthBox from "./auth-box";
import { handleLogout } from "../utils/auth-provider";
import { APP_ROUTES } from "../constants/routes";
import AppContext from "../utils/app-context";
import Breadcrumbs from "./Breadcrumbs";
import EditableField from "./editable-field";
import ForkProject from "../components/fork";
import ShareDialog from "../components/share-dialog";
import logo from "../images/logo.svg";
import theme from "../styles/theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import { nothing } from "immer";

const subnavStyles = {
  flexDirection: "row",
  maxWidth: "100%",
  display: "flex",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  padding: ".5em 3em .5em calc(3em - 6px)",
  //padding:"0 20px", 
  "&.title-head": {
    display: "block",
  },
  //height:'calc(110px - 1.5em)',
  ".title": {
    paddingRight: "25px",
    maxWidth: "33.3%",
    overflow: "hidden",
  },
  "h1": {
    padding: "10px 0",
    height: "36px",
    display: "flex", 
    alignItems: "center", 
    justifyContent: "left", 
  }, 
  ".text": {
    flex: "3 1 0",
    maxWidth: "66.6%",
    color: theme.palette.primary.light,
  },
  "div": {
    flex: "1 2 0",
  }, 
  "ul": {
    minHeight: "36px", 
    display: "flex", 
    flex: "2 1 0",
    "a": {
      lineHeight: "28px",
    },
  },  
  [theme.breakpoints.down("md")]: {
    padding: ".5em 1.5em .5em 1em",
//    padding:"0 10px", 
    //height:'calc(105px - .5em)',
    flexDirection: "column",
    alignItems: "flex-start",
    ".title": {
      paddingLeft: "calc(.5em - 6px)",
      paddingRight: "10px",
      h1: {
        fontSize: "18px",
        lineHeight: "28px",
      },
    },
    ul: {
      //paddingLeft: ".5em",
    },
    ".text": {
      fontSize: "12px",
    },
    ".filmed": {
      display: "none",
    },
  },
};
const headnavStyles = {
  padding: "1em 3em",
  //height:'calc(110px - 2em)',
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "div, a": {
    flex: "1 1 0",
  },
  button: {
    padding: "0 10px",
  }, 
  "button.account": {
    padding:0, 
  }, 
  [theme.breakpoints.down("md")]: {
    padding: ".5em 1.5em",
    //height:'calc(105px - .5em)',
    ".logo img": {
      width: "150px",
    },
    button: {
      padding: "0 5px",
    }, 
    "button.account": {
      padding:0, 
    }
  },
  [theme.breakpoints.down("sm")]: {
    padding: ".5em",
  }
};

const closeStyles = {
  position: "absolute",
  right: "2em",
  top: "1em",
  cursor:"pointer", 
  color: theme.palette.primary.light,
  background: theme.palette.primary.contrastText,
  [theme.breakpoints.down("md")]: {
    right: "1em",
    top: ".5em",
  },
};

const sideMenuStyles = {
  backgroundColor: theme.palette.primary.main,
  "> div": {
    background: theme.palette.primary.main,
    borderLeft: "1px solid " + theme.palette.primary.main,
    padding: "1em",
    minWidth: "200px",
  },
  ".close": {
    marginBottom: "2em",
  },
  a: {
    color: theme.palette.primary.main,
    display: "flex",
    textAlign: "left",
  },
  span: {
    padding: 0,
    display: "block",
    margin: "0 .5em 1em 0",
  },
  svg: {
    display: "block",
    marginRight: ".5em",
  },
  input: {
    color: theme.palette.primary.main,
    padding: 0,
  },
};


const Headbar = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [fixed, setFixed] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [movable, setMovable] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [timerAlert, setTimerAlert] = useState(false);
  const [timerNow, setTimerNow] = useState(null);
  const timerRef = useRef(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const story = state.stories.activeStory;
  const titleRoute = location.pathname === APP_ROUTES.STORY_BOARDS || location.pathname === APP_ROUTES.PREVIEW;
  
  const timerStyles = {
    color: timerAlert ? "red" : "white",
    padding: "5px 10px",
    border: "1px solid " + (timerAlert ? "red" : "white"),
  };

  useEffect(() => {
    if (location.pathname === "/storyboards") {
      setIsDeleted(false)
    } else {
      if (story && story.deleted_at) {
        setIsDeleted(true);
      } else {
        setIsDeleted(false);  
      }
    }
  }, [story]);  

  const extractCurrentId = (pathname) => {
    const parts = pathname.split('/');
    if (pathname.includes('/details/')) {
       
      return parts[parts.length - 2]; 
    }
    if (pathname.includes('/p/')) {
       
      const match = pathname.match(/^\/p\/([^/]+)/);
      return match ? match[1] : null;
      // return parts[parts.length - 1]; 
    }
    return null;
  };

  const currentId = extractCurrentId(location.pathname);
  const isStripPage = location.pathname.endsWith('/strip');
  const toggleShare = () => setShowShare((p) => !p);

  const isAdmin = props.admin || false;

  const medScreen = (useMediaQuery(theme.breakpoints.down("md"))) ? true : false
  const smallScreen = (useMediaQuery(theme.breakpoints.down("sm"))) ? true : false

  const logoAlign = smallScreen ? "left" : "center";

  const title = props.title ? props.title : story.title ? story.title : "Untitled";
  const shotId = props.shotId ? props.shotId : null;
  const text = props.text;
  const close = props.close;
  const breadcrumbs = props.breadcrumbs == false ? false : true;
  const timer = props.timer == false ? false : state.app.settings.isCompetition ? true : false;
  const shareActive = props.share == true ? true : false;
  const forkActive = props.fork == true ? true : false;
  const settingsActive = props.settings;
  const filmed = props.filmed;
  const output = [];
  const showTimer = props.timer != undefined ? props.timer : true;
  const starredActive = props.starred === true;  
  const portal_id = state.stories.activeStory?.portal_id || state.app.portal.id;
  const isStarred = state.stories.starredStories.includes(portal_id);
  const isLoggedIn = Object.keys(state.userData).length != 0 ? true : false

  const logout = () => { handleLogout(); }

  const handleToggleStar = () => {
    const portal_id = state.stories.activeStory?.portal_id || state.app.portal.id;

    console.log("handletogglestar", isLoggedIn)
    if(!isLoggedIn) setLoginOpen(true)
    else {
      console.dir('toggle star', isStarred)
      console.log("Star clicked for portal id: " + portal_id);
      console.log("log in", isLoggedIn)
      console.dir(state.userData)
      dispatch({
        type: "TOGGLE_STORY_STAR",
        payload: { isStarred: !isStarred, portal_id: portal_id }
      });
    }
  }

  const handleClose = () => {
    setLoginOpen(false);
  };

  const loginDialog =  <Dialog
        open={loginOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Star Project</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          <AuthBox version="login-modal" redirect={location.pathname} authFunc={handleToggleStar} 
            value_prop="Log in to star this storyboard." />
        </DialogContent>
      </Dialog>

  function share() {
    setShowShare(true);
  }

  function doShare() {
    alert("Text or email this link to let others add video:\nhttps://okapi-webdev.web.app/p/" + state.app.portal.id);
  }

  const getInitials = () => {
    if(state.userData && state.userData.name && state.userData.email) {
      const name = state.userData.name.split(' ')
      console.dir(name.length)
      if(name.length >= 2) return name[0].charAt(0).toUpperCase() + name[name.length - 1].charAt(0).toUpperCase();
      else if(name.length == 1) return name[0].charAt(0).toUpperCase();
      else return state.userData.email.charAt(0).toUpperCase();
    }
    return ""
  }

  const toggleCompetition = () => {
    const payload = { isCompetition: story.isCompetition == undefined ? true : !story.isCompetition };
    dispatch({ type: "TOGGLE_STORY_COMPETITION", payload: payload });
  };

  const timerInt = () => {
    const interval = setInterval(() => {
      setTimerNow(Date.now());
    }, 1000);
    timerRef.current = interval;
  };
  let timerSeconds;
  let timerDisplay = null;
  let minutes = 0;
  let seconds = 0;
  if (!timerNow) setTimerNow(Date.now());
  if (story.isCompetition) {
    let timerSeconds = 1800;
    if (story.timerStart != undefined && story.timerStart != null && !timerRef.current) {
      timerInt();
    }
    if (story.timerStart != undefined && story.timerStart != null && timerNow != null) {
      timerSeconds = 1800 - ((timerNow - story.timerStart) / 1000).toFixed(0);
      if (timerSeconds > 1800) timerSeconds = 1800;
    }
    if (timerSeconds <= 0) {
      if (timerAlert != true) setTimerAlert(true);
      timerDisplay = "0:00";
    } else {
      if (timerSeconds < 300 && timerAlert != true) setTimerAlert(true);
      else if (timerSeconds > 300 && timerAlert == true) setTimerAlert(false);
      minutes = Math.floor(timerSeconds / 60);
      seconds = timerSeconds - minutes * 60;
      timerDisplay = minutes.toString() + ":" + (seconds < 10 ? "0" + seconds : seconds.toFixed(0).toString());
    }
  }

  function open_sidebar() {
    setSidebarVisible(true);
  }

  function close_sidebar() {
    setSidebarVisible(false);
  }

  function setStoryTitle(story_id, title) {
    const payload = { proj_id: story_id, title: title };
    dispatch({ type: "UPDATE_STORY_TITLE", payload: payload });
  }

  function setShotDescription(story_id, text) {
    const payload = { shot_id: shotId, description: text };
    dispatch({ type: "UPDATE_STORY_SHOT_DESCRIPTION", payload: payload });
  }

  function setShotTitle(story_id, title) {
    const payload = { shot_id: shotId, text: title };
    dispatch({ type: "UPDATE_STORY_SHOT_TITLE", payload: payload });
  }

  /*function toggle_movable() {
    setMovable(!movable)
  }*/

  const verticalSidebar = (
    <Drawer
      /*as={Menu}
      animation='overlay'
      direction='right'
      icon='labeled'
      inverted='true'
      vertical="true"
      visible={visible}
      width='thin'*/
      variant="persistent"
      anchor="right"
      open={sidebarVisible}
      sx={sideMenuStyles}
    >
      <Link className="close" onClick={close_sidebar}>
        <Close sx={{ color: theme.palette.primary.contrastText }} />
      </Link>
      {/*Object.keys(state.userData).length && <Link href="/account" onClick={(e) => { close_sidebar(); history.push(e.target.parentNode.getAttribute('href')); e.preventDefault(); }}
        sx={{ marginBottom: "20px", "&:hover": { cursor: "pointer" } }}
      >
        <Settings sx={{ color:theme.palette.primary.contrastText, marginRight:"10px" }} />
        <Typography sx={{ color: theme.palette.primary.contrastText }}>Manage Account</Typography>
      </Link>*/}
      {settingsActive && state.app.userIsStoryOwner && (
        <>
          <Link
            onClick={() => {
              close_sidebar();
              settingsActive.storyboard.action(true);
            }}
            sx={{ marginBottom: "20px", "&:hover": { cursor: "pointer" } }}
          >
            <AutoFixHigh sx={{ color: theme.palette.primary.contrastText }} />
            <Typography sx={{ color: theme.palette.primary.contrastText }}>Auto Storyboard</Typography>
          </Link>
          <Link
            onClick={() => {
              close_sidebar();
              toggleCompetition();
            }}
            sx={{ marginBottom: "20px", "&:hover": { cursor: "pointer" } }}
          >
            <SportsScore sx={{ color: theme.palette.primary.contrastText }} />
            <Typography sx={{ color: theme.palette.primary.contrastText }}>Toggle Competition</Typography>
          </Link>
          {/* view as strip */}
          {isStripPage ? (
            <Link
              onClick={() => {
                close_sidebar();
                history.goBack()
              }}
              sx={{ marginBottom: "20px", "&:hover": { cursor: "pointer" } }}
            >
              <GridOn sx={{ color: theme.palette.primary.contrastText }} />
              <Typography sx={{ color: theme.palette.primary.contrastText }}>View as board</Typography>
            </Link>
          )
            : (
              <Link
                onClick={() => {
                  close_sidebar();
                  history.push(`/p/${currentId}/strip`);
                }}
                sx={{ marginBottom: "20px", "&:hover": { cursor: "pointer" } }}
              >
                <ViewDay sx={{ color: theme.palette.primary.contrastText }} />
                <Typography sx={{ color: theme.palette.primary.contrastText }}>View as Strip</Typography>
              </Link>
            )
          }
        </>
      )}
      {state.userData.zebradmin && <Link
        onClick={() => {
          close_sidebar();
          logout();
        }}
        sx={{ marginBottom: "20px", "&:hover": { cursor: "pointer" } }}
      >
        <Logout sx={{ color: theme.palette.primary.contrastText }} />
        <Typography sx={{ color: theme.palette.primary.contrastText }}>Logout</Typography>
      </Link>}
    </Drawer>
  );
  /*buttons.map((button, key) => {
    if(button != null) {
      if(key==buttons.length-1) {
        output.push(<Button variant="contained" sx={{backgroundColor: 'black', color: 'white', marginLeft:"30px"}} onClick={() => 
          window.location = button.href
        }>{button.text}</Button>)
      } else {
        output.push(<Button variant="contained" sx={{backgroundColor: 'white', color: 'black'}} onClick={() => 
          window.location = button.href
        }>{button.text}</Button>)
      }
    }
  })*/
  return (
    <>
      <Box component="header">
        <Box sx={headnavStyles}>
          <Box sx={{ display:'flex' }}>
            {Object.keys(state.userData).length &&
            <Link href="/account/credits" title="Add Credits" onClick={(e)=> { history.push(e.target.parentNode.getAttribute("href")); e.preventDefault(); }} underline="hover" className="credits" sx={{ display:'flex', flexGrow:0, alignItems:"center", '&:hover': { cursor:'pointer' } }}>
              <Toll sx={{ color:theme.palette.primary.contrastText, marginRight:"10px" }} />
              {!smallScreen && <Typography sx={{ color: theme.palette.primary.contrastText }}>{state.userData.credits}</Typography>}
            </Link>}
          </Box>
          <Link
            sx={{ textAlign: logoAlign }}
            onClick={(e) => {
              history.push(e.target.parentNode.getAttribute("href")); e.preventDefault();
            }}
            href="/storyboards"
            className="logo"
          >
            <img src={logo} />
          </Link>
          <Box sx={{ textAlign: "right"}}>
            <Box sx={{ float: "right", display: "flex", alignItems: "center", position:"relative" }}>
              {story.isCompetition && showTimer && <Box sx={timerStyles}>{timerDisplay}</Box>}
              {(state.app.userIsStoryOwner || state.app.settings.communityCanStarred) && starredActive && (
                <IconButton onClick={handleToggleStar}>
                  {isStarred ? (
                    <Star sx={{ color: '#fff' }} />
                  ) : (
                    <StarOutline sx={{ color: '#fff' }} />
                  )}
                </IconButton>
              )}
              {(state.app.userIsStoryOwner || state.app.settings.communityCanForkWithMedia) && forkActive && <ForkProject />}
              {(state.app.userIsStoryOwner || state.app.settings.communityCanShare) && shareActive && (
                <IconButton
                  onClick={(e) => {
                    setShowShare(true);
                  }}
                  sx={{ display: "inline-block" }}
                >
                  <Share sx={{ color: theme.palette.primary.contrastText }} />
                </IconButton>
              )}
              {Object.keys(state.userData).length && <IconButton onClick={ open_sidebar } className="account" sx={{ textDecoration:"none", cursor:"pointer" }}>
              {console.dir("user data", state.userData)}
                {state.userData && <Box component="img" src={state.userData.photoURL} sx={{ borderRadius:"50%", display:"block" }} width="46px" height="46px" />}
              </IconButton>}
            </Box>
          </Box>
        </Box>

        {
          isDeleted ?
            null
            : <>
              <Box vertical="true" sx={{ ...subnavStyles, overflow: 'hidden' }}

                className={!breadcrumbs && filmed == undefined ? "title-head" : ""}>
                {(!shotId || !state.app.userIsStoryOwner) && (
                  <>
                    <Box className="title">
                      {state.app.userIsStoryOwner && !medScreen && !titleRoute && (
                        <Typography variant="h1">
                          <EditableField init={title} setter={setStoryTitle} story_id={story.title} textClassName="input input__cd title_cd" num_rows={1} max_length={500} />
                        </Typography>
                      )}

                      {(!state.app.userIsStoryOwner || titleRoute) && (
                        <Typography variant="h1">
                          {title}
                        </Typography>
                      )}
                    </Box>
                    {text && (
                      <Typography variant="body2" className="text">
                        {text}
                      </Typography>
                    )}
                  </>
                )}
                {shotId && state.app.userIsStoryOwner && (
                  <>
                    <Box className="title">
                      <EditableField init={title} setter={setShotTitle} story_id={title} textClassName="input input__cd title_cd" num_rows={1} max_length={32} />
                    </Box>
                    {text &&
                      <Box className="text">
                        <EditableField init={text} setter={setShotDescription} story_id={text} textClassName="input input__cd title_cd" num_rows={1} max_length={250} />
                      </Box>}
                  </>
                )}
                {breadcrumbs && (
                  <Breadcrumbs owner={state.app.userIsStoryOwner} admin={ isAdmin ? true : false } />
                )
                }
                {filmed && (
                  <Box sx={{ textAlign: "right" }} className="filmed">
                    {filmed}
                  </Box>
                )}
                {close && (
                  <Link onClick={close} sx={closeStyles} className="close">
                    <Close />
                  </Link>
                )}
                {breadcrumbs && !close && !filmed && <Box variant="div" className="empty"></Box>}
              </Box>
            </>
        }
      </Box>
      {verticalSidebar}
      { !isLoggedIn && loginDialog }
      <ShareDialog open={showShare} toggleShare={toggleShare} />
    </>
  );
};

export default Headbar;