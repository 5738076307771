import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const GenericModal = ({ open, onClose, title, children, actionText, onAction }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle >
                {title}
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 14,
                        top: 8,
                        color: (theme) => theme.palette.grey[800],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            
            
                <DialogActions>
                    <Button onClick={onAction} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    {actionText && (
                        <Button onClick={onAction} variant="contained" color="primary">
                            {actionText}
                        </Button>
                    )}
                </DialogActions>
        </Dialog>
    );
};

export default GenericModal;
