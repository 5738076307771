import React, { useContext, useState, useEffect } from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
//import { withStyles } from '@mui/styles';
import theme from "../styles/theme";

import AppContext from "../utils/app-context";

//  custom Alert styling avoided.   Using default Snackbar instead.
// const useStyles = makeStyles({
//     alert: {
//         position: "absolute",
//         top: '30px',
//         left: '50%',
//         zIndex: 100,
//         transform: 'translate(-50%, 0%)',
//         width: "600px",
//         maxWidth: "95vw",
//         boxShadow: "0 0.5rem 1rem #808080",
//     }
// });

export default function AlertUploadStatus() {


    const { state } = useContext(AppContext);
    const [message, setMessage] = useState(["Uploading and cropping", "", "image "]);
    const [progress, setProgress] = useState(0);
    const [bytesProgress, setBytesProgress] = useState(0);

    const bytesReducer = (acc, curr) => acc + curr;

    const data = state.app.upload.data || {}

    const sizeInMB = (sizeInBytes) => (sizeInBytes / (1024 * 1024)).toFixed(2);

    useEffect(() => {
        if (data.totalFiles > 1) {
            setMessage(["Uploading", data.totalFiles, "videos "]);
        } else {
            setMessage(["Uploading", "", "video "])
        }
        if (data.fileBytes) {
            let bytes = Object.values(data.fileBytes).reduce(bytesReducer, 0);
            setProgress(100 * bytes / data.totalBytes);
            setBytesProgress(sizeInMB(bytes) + ' / ' + sizeInMB(data.totalBytes) + 'MB');
        }

    }, [data.totalFiles, data.fileBytes, data.totalBytes, bytesProgress])

    const beforeUnload = (evt) => {
        evt.preventDefault();
        evt.returnValue = true;
    }

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnload);

        return () => window.removeEventListener('beforeunload', beforeUnload);
    })

    if (!state.app.upload) {
        return (<></>)
    }

    return (
         <Snackbar open={true} >
            <Alert
                key={'alert'}
                severity='info'
                className='nothingCustom'>
                {message[0]} <b>{message[1]}</b> {message[2]} <b> {bytesProgress} </b><br />
                <LinearProgress variant="determinate" value={progress} />
            </Alert>
         </Snackbar>
    )
}