import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material/';


export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [consentedOnce, setConsentedOnce] = React.useState(false);

  const handleClickOpen = () => {
    if (consentedOnce) {
      props.doIfConfirmed()
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setConsentedOnce(true)
    setOpen(false);
    props.doIfConfirmed()
  };

  return (
    <div>
      <Button variant="outlined" visible={false} onClick={handleClickOpen}>
        {props.name}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {props.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            No
          </Button>
          <Button onClick={handleYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}