import React, { useState } from 'react';


function StoryPartForm(props) {

  const [part, setPart] = useState(props.init);

  function handleChange(e) {
    props.set(e.target.value)
    setPart(e.target.value)
  }

  return (
    <form>
        <div className="container">
        <input
          type="text"
          id="new-todo-input1"
          className="input input__lg"
          name="title"
          autoComplete="off"
          value = {part}
          onChange={handleChange}
        />
        </div>
      </form>
    );
}

export default StoryPartForm;