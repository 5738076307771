import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box, Button, withTheme, Icon } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CircleIcon from '@mui/icons-material/Circle'
import { css } from '@emotion/css'
import navLogo from '../images/nav-logo.svg'
import theme from '../styles/theme'


const Footbar = (props) => {

  const [fixed, setFixed] = useState(false)
  const history = useHistory();

  const styles = {
    position:"fixed", 
    width:"calc(100% - 4em)", 
    zIndex:999, 
    padding:"1em 2em", 
    backgroundColor:"white", 
    flexDirection:"row", 
    bottom:0, 
    display:"flex", 
    justifyContent:"space-between",
    alignItems:"center", 
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)", 
    button: {
      marginLeft: "30px"
    }, 
    [theme.breakpoints.down('md')]: {
      position:"fixed", 
      padding:".5em 1em", 
      width:"calc(100% - 2em)", 
      flexDirection: "column-reverse", 
      /*".right" : {
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "end"
      },*/ 
      button: {
        margin: "0 0 10px 10px", 
        padding:"6px 12px", 
        "&:first-of-type": {
          marginLeft: "0"
        }
      }
    }, 
    [theme.breakpoints.down('sm')]: {
      //position:"static", 
      button: {
        margin: "0 0 10px 8px", 
        padding:"6px 8px"
      }
    }
  }

  const buttonStyles = {}
   /* [theme.breakpoints.down('sm')]: {
      display:'flex', 
      width:'100%', 
      flexDirection:'column-reverse', 
      button: {
        width:'100%', 
        margin:'0 0 1em', 
        '&:last-child': {
          marginBottom:'0', 
        }
      }
    }
  }*/

  const buttons = props.buttons
  const output=[]
  const iconMap = {
    "play": <PlayArrowIcon />, 
    "circle": <CircleIcon />
  }
  const clickHandler = () => {
    if(button.href) history.push(button.href)
    else if(button.click) button.click()
  }
  let icon=<></>
  {buttons && buttons.map((button, key) => {
    if(button != null) {
      if(button.icon) icon = null //icon = iconMap[button.icon]
      if(button.type == "secondary") {
        output.push(<Button key={key} variant="contained" disabled={button.disabled} color="secondary" onClick={() => {
          if(button.href) (button.href)
          else if(button.click) button.click()
      }}>{icon}{button.text}</Button>)
      } else if(button.type == "tertiary") {
        output.push(<Button key={key} variant="outlined" disabled={button.disabled} onClick={() => {
          if(button.href) history.push(button.href)
          else if(button.click) button.click()
      }}>{icon}{button.text}</Button>)
      } else {
        output.push(<Button key={key} variant="contained" disabled={button.disabled} onClick={() => {
          if(button.href) history.push(button.href)
          else if(button.click) button.click()
      }}>{icon}{button.text}</Button>)
      }
    }
  })}


  return <Box vertical="true" right sx={styles}>
    <Box className="left">
      Made with <a href="https://gen.10zebra.com/" target="_blank">10zebra</a>.
    </Box>
    <Box className="right" sx={ buttonStyles }>
      {output}  
    </Box>
  </Box>
}

export default Footbar