import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../utils/app-context";
// import "../components/pmm.css"
import RecordableShotGroup from "../components/recordable-shot-group";
import AlertUploadStatus from './alert-upload-status';
import ShareDialog from "../components/share-dialog";
import ViewToggle from "../components/toggle-view";

import { Typography, Icon, Box, Radio } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'



const init_shots = [{
    header: 'Sample Shot',
    description: 'A short descrition so contributors know what to film.  Like "People having fun as they wait in line to enter"',
    meta: 'Outside the venue',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg"
  }
]

function Produce2(props) {
    const [displayImages, setDisplayImages] = useState(true)
    const [displayShare, setDisplayShare] = useState(true)
    const [viewLatestTake, setViewLatestTake] = useState(true);

    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();

    const story = state.stories.activeStory //state.story //state.stories.activeStory
    const takes = state.stories.activeTakeList // this is all takes in the story
    const shots = state.stories.activeShotList // this is all undeleted shots

    function toggleDisplayView() {
      setDisplayImages(!displayImages) 
    }

    function runTest() {
      alert("all tests passed") 
    }

    function OnFileUploadChange() {
      alert("OnFileUploadChange") 
    }

    function doShare() {
          alert("Text or email this link to let others add video:\nhttps://okapi-webdev.web.app/p/" + state.app.portal.id) 
    }

    function doInfo() {
        const msg = "Help us collect footage to make a video that follows this storyboard. Tap the image in any planned shot to upload a new take 1-6 seconds long. Thanks!"
        const msg2 = msg + `\n\n id: ${state.app.portal.id}`
        alert(msg2)
      }

    function doEdit() {
          //todo:  consider when the story selected is not last active project ...
          //dispatch({ type: "UPDATE_ACTIVE_STORY_REF" })
          // userRef.update({ lastActiveStory: activeStoryRef })
          history.push('/p/' + portal_id);
    }

    const vestigal_header =         <div className="filters btn-group stack-exception">
          <button type="button" className="btn toggle-image-display" onClick={toggleDisplayView}> View </button>
          <button type="button" className="btn toggle-image-display" onClick={runTest}> Test </button>
          <button type="button" className="btn toggle-image-display" onClick={doShare}> Share </button>
        </div>

    const vestigal_share1 =   <button type="submit" className="btn__inline" onClick={doShare}> Share </button>

   const vestigal_share2  =    <ShareDialog name="share" open="false" />

    return( 
    
      <div className="Scene app_container">
        {state.app.upload && <AlertUploadStatus />}

        <Box>
          <Typography variant='h2'>     
              Gala Community Video - Nov. 7, 2021
          </Typography>
          <Typography variant='h3'>     
              <ViewToggle viewLatestTake={viewLatestTake} setViewLatestTake={setViewLatestTake}/>
          </Typography>

          <Typography variant='h5'>  
                {state.app.portal.id && vestigal_share2}
           </Typography>
          {state.app.userIsStoryOwner && <Box>     
              <Icon name='edit' onClick={doEdit}/>
          </Box>}
        </Box>

        <Divider fitted />


        <h4> Add a video clip to any planned shot.  <Icon name=' blue info circle' onClick={doInfo} inverted/> </h4>
        
        <br />

        {story.parts && story.parts.map((part, key) => (
          <RecordableShotGroup id={key} title={part.title}  displayImages={displayImages} 
              viewLatestTake={viewLatestTake} 
              handleShotDelete={OnFileUploadChange} 
              shots={part.order.map(shot_id => shots.filter(shot => shot.id===shot_id)[0])}
              takes={takes}
          />
        ))}

       <Box>
        <Grid columns={5} stackable>
          <Grid.Column></Grid.Column>
          <Grid.Column>
              <a href="https://fundraising-ncm.myshopify.com?v=okv"> Support </a>
          </Grid.Column>
            <Grid.Column>
              <a href="https://fundraising-ncm.myshopify.com/collections/fund-a-need?v=okv"> Fund a need </a>
          </Grid.Column>
          <Grid.Column>
              <a href="https://fundraising-ncm.myshopify.com/collections/buy-the-art?v=okv"> Buy the art </a>
          </Grid.Column>
        </Grid>
      </Box>

      </div>
    )
}


export default Produce2