import React, { useContext } from 'react';
import { AuthContext } from "../utils/auth-provider";
import { analytics } from '../utils/firebase.js'
import { logEvent } from "@firebase/analytics";
import Loading from './loading';
import AuthBox from './auth-box';

export default function Login() {

    const { pending } = useContext(AuthContext);

    const rules = [
        // if it says it's a webview, let's go with that
        'WebView',
        // iOS webview will be the same as safari but missing "Safari"
        '(iPhone|iPod|iPad)(?!.*Safari)',
        // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
        'Android.*Version/[0-9]\.[0-9]',
        // Also, we should save the wv detected for Lollipop
        // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
        'Android.*wv',
        // old chrome android webview agent
        'Linux; U; Android'
      ]
      
      var webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')
      
      const isWebview = (ua) => {
        return !!ua.match(webviewRegExp)
      }

    if (pending) {
        return <Loading />
    } else if(isWebview(window.navigator.userAgent)) {
        logEvent(analytics, 'WEBVIEW_LOGIN_ATTEMPT');
        /*window.open(window.location.href, '_system');
        return;*/
    }
    return (
        <AuthBox version="login" />
    )
}
