// app-context.js
import React, { createContext, useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import firebase, { db, analytics, batch } from "../utils/firebase.js";
import { doc, collection, setDoc, addDoc, deleteDoc, getDoc, getDocs, query, where, onSnapshot, serverTimestamp, writeBatch, increment } from "firebase/firestore"; 
import { logEvent } from "firebase/analytics";
import { AuthContext } from "../utils/auth-provider";
import { matchPath, useLocation} from "react-router";
import { nanoid } from "nanoid";
import { v4 as uuidv4 } from 'uuid';
import assets from "../utils/assets";
import { emptyStory } from "../utils/utility";

// import { storyConstructor, albumConstructor } from "../utils/data"

const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
const isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

// Initial app context state
// TODO: load from the database
const initialStory = {
        parts: [{title:"Sample",order:["id-4NNPGRgIT6CbzCXLNHerL",
                "id-4NNPGRgIT6CbzCXLNHerM", "id-4NNPGRgIT6CbzCXLNHerN"]}],
        title: "Sample Storyboard", 
        target_duration: 15,
        pace: 1.5,
        portal_id: nanoid(12),
        num_shots: null,  
        created: null, 
        deleted_at: null, 
        timerStart:null,
        isCompetition:false, 
        last_cut: null, 
        shots: [{ header: "Goal",
            description: 'HERO wants X',
            meta: 'Establish character & context',
            image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb009a.jpg",
            id: "id-4NNPGRgIT6CbzCXLNHerL",
            deleted_at: null
          },
          { header: 'Challenge',
            description: 'Faces OBSTACLE',
            meta: 'Emotional arc before & after obstacle',
            image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb004a.jpg",
            id: "id-4NNPGRgIT6CbzCXLNHerM",
            deleted_at: null      
          },
          { header: "Outcome",
            description: 'This is what happened',
            meta: 'Reveal uncertainty',
            image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg",
            id: "id-4NNPGRgIT6CbzCXLNHerN",
            deleted_at: null
          }]
    }

const initialState = {
    story: initialStory,
    app: {
        isLoading: true,
        isMobile,
        alerts: {},
        modal: {},
        upload: null,
        importing: null,
        userIsStoryOwner: false,
        prioritizeLivePreview: true,
        previewVolume: 1, 
        forkingStory: null, 
        initAndFork: null, 
        savingCut: null, 
        portal: {
            id: null,
            requested_id: null,
            },
        contributedCount: 0,
        controls: {
            backSelectOpen: false,
            addFabOpen: false,
            flip: false,
            drawerIsOpen: false,
            storiesOpen: false,
            sharedStorysOpen: false,
            inspirationOpen: false,
        },
        cardPreviews: {},
        cardPrice: 2,
        settings: {
            promptsVisible: true,                   // impacts app & stored in firebase, can be set on a settings page. 
            communityCanVeneer: true,               // impacts app & stored in firebase, an be set on a settings page. 
            communityCanUpload:true,
            communityCanCamera:true,
            communityCanToggleLivePreview: false,   // impacts app & stored in firebase, an be set on a settings page. 
            communityCanViewSavedPreview: true,     // This lets community see high res color (and thus could download it)
            communityCanDownloadTakes: true,
            communityCanDeleteTakes: false,         // this does allow community members to remove from firebase for view, but does not delete files in the backend
            communityCanListenToTakes: true,        // 
            savedPreviewHasAudio: true, 
            proxyIsBlackAndWhite: false,
            ytImportIsBlackAndWhite: false,
            userCanUploadLargeFiles: true,
            userCanListenToTakes: true,       
            communityCanForkWithMedia: true,          
            communityCanShare: true,
            communityCanStarred:true,
            // communityCanSelectTakes: true,          // tested and never rolled out to prod: we disabled suppport of state.app.settings.communityCanSelectTakes - its effectively false till we manage the data model and can confirm on dev
            // communityShotDescriptionEdit: true,  // an idea:  not used yet
            // communityCanDownload: false,         // an idea:  not used yet --  pershot, this has deeper firebase permission settings
        },
    },
    userData: {},
    stories: {
        storyList: null,
        sharedStoryList: [],
        activeStoryRef: null,
        nextStoryMetadata: null,
        activeStory: {},
        activeShotList: [],
        activeTakeList: [], 
        activeVeneerLUT: {},
        albumSourceSet: new Set(),
        starredStories:[],
        generateStory: null,
    },
    inspiration: {
        allbumlist: [],
        initialAlbumID: 'ebb61632-8c51-47b3-a2e2-8ccf4373bde4',
        activeAlbumRef: null,
        activeAlbum: {},
        activeAlbumCardList: []
    }
};

// AppContext is the high level data store context for the App
const AppContext = createContext(null);

export function AppProvider({ children }) {

    // Check to see if the user is authenticated before fetching data
    const { currentUser } = useContext(AuthContext);
    // Main reducer for App context
    const reducer = (draft, action) => {
        const storyRef = draft.stories.activeStory.ref
        const shotRef = ("payload" in action && "shot_id" in action.payload && action.payload.shot_id != null) ? doc(storyRef, 'shots', action.payload.shot_id) : null
        const takeRef = ("payload" in action && "take_id" in action.payload && action.payload.take_id != null) ? doc(storyRef, 'takes', action.payload.take_id) : null

        // const userRef = (action.type.search("USER") > -1) ? await setDoc(doc(db, 'users').doc(currentUser.uid) : 0
        switch (action.type) {
            /*case "TOGGLE_USER_CAN_LISTEN_TO_TAKES":
                draft.app.settings.userCanListenToTakes = !draft.app.settings.userCanListenToTakes;
                return draft;*/

            // toggle story star function
            case "TOGGLE_STORY_STAR": {
                //const { currentUser } = useContext(AuthContext);
                if (!currentUser) {
                    console.error("User is not authenticated");
                    return draft; 
                }
                const userRef = doc(db, 'users', currentUser.uid);
                const { isStarred, portal_id } = action.payload;
                const starredPortalRef = doc(userRef, 'starred_portals', portal_id);
                if (isStarred) {
                    setDoc( starredPortalRef, { portal_id: portal_id }, {merge:true})
                    .then(() => {
                draft.stories.starredStories.push(portal_id); 
                console.log(`Portal ${portal_id} starred`);
                })
            .catch((error) => console.error("Error starring portal: ", error));
                    } else {
                deleteDoc(starredPortalRef)
                 .then(() => {
                draft.stories.starredStories = draft.stories.starredStories.filter(id => id !== portal_id); // Remove the story from the state
                console.log(`Portal ${portal_id} unstarred`);
                 })
                .catch((error) => console.error("Error unstarring portal: ", error));
                }
                return draft 
            }
            case "UPDATE_STORY_PARTS":
                //draft.story.parts = action.payload
                setDoc(storyRef, {"parts": action.payload}, {merge:true})
                return draft;
            case "UPDATE_STORY_SHOTS":
                const part_index = action.payload.part_index
                // draft.story.shots = [...draft.story.shots, ...action.payload.shots]
                // draft.story.parts[part_index].order = action.payload.shots.map(shot => shot.id)

                // we have the option to remove previous shots, or tag them as deleted
                // we need to delete if we want the counter to be accurate ...
                draft.stories.activeStory.parts[part_index].order.forEach((shot_id) => {
                    setDoc(storyRef, 'shots', shot_id,
                        {"deleted_at": serverTimestamp()}, {merge:true}
                )})
                if (storyRef){
                    console.log('story ref')
                    action.payload.shots.forEach((shot) => {
                        const shotRef = doc(storyRef, 'shots', shot.id);
                        setDoc(shotRef, shot, {merge:true})
                            .then(() => shotRef)
                        .catch((error) => {
                            console.log('Error creating new shot:', shotRef)
                        })
                    })
                    var new_parts = draft.stories.activeStory.parts
                    new_parts[part_index].order = action.payload.shots.map(shot => shot.id)
                    setDoc(storyRef, {"parts": new_parts}, {merge:true})
                }
            
            case "UPDATE_STORY_TITLE":
                if(storyRef) {
                    setDoc(storyRef, {"title": action.payload.title}, {merge:true})
                }
                return draft

            case "UPDATE_STORY_DESCRIPTION":
                if(storyRef) {
                    setDoc(storyRef, {"description": action.payload.description}, {merge:true})
                }
                return draft

            case "START_STORY_TIMER":
                const timerStart = action.payload.timerStart

                if (storyRef){

                    setDoc(storyRef, {"timerStart": timerStart}, {merge:true})
                }
                return draft

            case "TOGGLE_STORY_COMPETITION":
                const isCompetition = action.payload.isCompetition
                if (storyRef){
                    setDoc(storyRef, {"isCompetition": isCompetition, "timerStart": null}, {merge:true})
                }
                return draft

            case "TOGGLE_VOLUME":
                const volume = action.payload.volume

                draft.app.previewVolume = volume

                return draft
            case "ADD_STORY_SHOT": {
                    const { part_id, index, shot } = action.payload;
                
                    // Find the index of the part where the shot needs to be added
                    const part_ind = draft.stories.activeStory.parts.findIndex(part => part.id === part_id);
                
                    if (part_ind === -1) {
                        console.error("Part ID not found");
                        return draft; // Exit early if part_id is invalid
                    }
                
                    const parts = draft.stories.activeStory.parts;
                
                    // Update the order array in the specified part
                    if (typeof index === "number") {
                        parts[part_ind].order = [
                            ...parts[part_ind].order.slice(0, index),
                            shot.id,
                            ...parts[part_ind].order.slice(index)
                        ];
                    } else {
                        parts[part_ind].order = [...parts[part_ind].order, shot.id];
                    }
                
                    // Write the new shot to Firestore and update the part order
                    if (shotRef) {
                        try {
                            const batch = writeBatch(db);
                
                            // Add the new shot to the "shots" collection
                            const shotRef = doc(storyRef, "shots", shot.id);
                            batch.set(shotRef, shot, { merge: true });
                
                            // Update the "parts" field in the story
                            batch.set(
                                storyRef,
                                { parts },
                                { merge: true }
                            );
                
                            // Commit the batch
                             batch.commit();
                            console.log("Shot added and parts updated successfully.");
                        } catch (error) {
                            console.error("Error creating or updating shot:", error);
                        }
                    } else {
                        console.error("shotRef is not defined.");
                    }
                
                    return draft; // Immer will finalize the updated draft object
                }
                
            case "MOVE_STORY_SHOT":
                const source_part_id = action.payload.source_part_id
                const dest_part_id = action.payload.dest_part_id
                const moving_shot_id = action.payload.moving_shot_id
                const preceding_shot_id = action.payload.preceding_shot_id

                var new_parts = draft.stories.activeStory.parts

                const source_part_index = new_parts.map(part => part.id).indexOf(source_part_id) 
                if (source_part_index === -1) {
                    console.error("MOVE_STORY_SHOT non-existant part!", source_part_id)
                }

                const dest_part_index = new_parts.map(part => part.id).indexOf(dest_part_id) 
                if (dest_part_index === -1) {
                    console.error("MOVE_STORY_SHOT non-existant part!", dest_part_index)
                }

                const dest_shot_index = preceding_shot_id === "000" ? 0 : new_parts[dest_part_index].order.indexOf(preceding_shot_id) + 1
                // console.log("MOVE_STORY_SHOT dest_shot_index", dest_shot_index, preceding_shot_id)

                // first we remove the moving shot
                new_parts[source_part_index].order = new_parts[source_part_index].order.filter(id => id!==moving_shot_id) 

                // then we add its id to the destination part
                // new_parts[dest_part_index].order = [...new_parts[dest_part_index].order, moving_shot_id] // at the end
                new_parts[dest_part_index].order.splice(dest_shot_index, 0, moving_shot_id) // where it was dropped

                setDoc(storyRef, {"parts": new_parts}, {merge:true})

                return draft;
            case "DELETE_STORY_SHOT":
                if (!storyRef || !shotRef) {
                    console.error("Missing storyRef or shotRef for shot deletion");
                    return draft;
                }

                // Create a copy of parts before starting async operation
                const partsBeforeDelete = JSON.parse(JSON.stringify(draft.stories.activeStory.parts));
                
                // Update local state immediately
                draft.stories.activeStory.parts = partsBeforeDelete.map(part => ({
                    ...part,
                    order: part.order.filter(id => id !== action.payload.shot_id)
                }));

                // Handle database updates
                (async () => {
                    try {
                        const delBatch = writeBatch(db);
                        
                        // Mark shot as deleted
                        delBatch.set(shotRef, {
                            "deleted_at": serverTimestamp()
                        }, {merge: true});

                        // Update story parts
                        delBatch.set(storyRef, {
                            "parts": partsBeforeDelete.map(part => ({
                                ...part,
                                order: part.order.filter(id => id !== action.payload.shot_id)
                            }))
                        }, {merge: true});

                        // Commit the batch
                        await delBatch.commit();
                    } catch (error) {
                        console.error("Error deleting shot:", error);
                        // Revert local state on error
                        draft.stories.activeStory.parts = partsBeforeDelete;
                    }
                })();

                return draft;

            case "DELETE_STORYBOARD":
                const { boardRef } = action.payload;
                //const delete_storyboard_batch = boardRef.set();
                //const storyboardRef = await setDoc(doc(db, 'portals').where('story_id', '==', portal_id)

                setDoc(boardRef, { "deleted_at": serverTimestamp() }, {merge:true});

                return draft;





            case "DELETE_TAKE":
                //takeRef.update({"deleted_at": firebase.firestore.FieldValue.serverTimestamp()})
                //const delete_take_batch = db.batch()
                setDoc(takeRef, {"deleted_at": serverTimestamp()}, {merge:true})
                var shot_payload = {}
                if (action.payload.shot_selected_take_id == action.payload.take_id) {
                    shot_payload["selected_take_id"] = null
                    shot_payload["selected_take_stream_url"] = null
                    shot_payload["selected_take_im"] = null
                }
                if (action.payload.shot_last_take_id == action.payload.take_id) {
                    shot_payload["last_take_stream_url"] = null
                    shot_payload["last_take_im"] = null
                    shot_payload["last_take_id"] = null
                }
                if (Object.keys(shot_payload).length > 0) { 
                    setDoc(shotRef, shot_payload, {merge:true})
                }

                //delete_take_batch.commit()

                return draft

            case "UPDATE_STORY_SHOT_DESCRIPTION":
                // function replaceDescIfIdMatches(shot, payload) {
                //     if (shot.id === payload.shot_id) {shot.description = payload.description}
                // }
                // draft.story.shots.map(shot => replaceDescIfIdMatches(shot, action.payload))

                setDoc(shotRef, {"description": action.payload.description}, {merge:true})

                return draft

            case "MOVE_STORY_SHOT":

                // action.payload.shot_id

                // console.log('Proposed move:', (action.payload.shot_id, action.payload.source_part_id, action.payload.dest_part_id, action.payload.preceding_shot_id))

                //  similar logic as onDragEnd()  goes here

                return draft

            // case "UPDATE_STORY_SHOT_TITLE":
            //     // function replaceTitleIfIdMatches(shot, payload) {
            //     //     if (shot.id === payload.shot_id) {shot.header = payload.text}
            //     // }
            //     // draft.story.shots.map(shot => replaceTitleIfIdMatches(shot,action.payload))
            //     setDoc(shotRef, {"header": action.payload.text})
            //     return draft

            case "CREATE_VENEER_FOR_TAKE":
                const { veneer_id, veneer, cost, userData, story } = action.payload;
                const veneerPromise = setDoc(doc(storyRef, "veneer", veneer_id), veneer, {merge:true}).then(() => {
                    dispatch({ type: "START_VENEER", payload: {} });
                    dispatch({ type: "UPDATE_USER_CREDITS", payload: { credits: userData.credits - cost } });
                    if (story.isCompetition && (story.timerStart == undefined || story.timerStart == null)) {
                        const timerStart = Date.now();
                        const payload = { timerStart: timerStart };
                        dispatch({ type: "START_STORY_TIMER", payload: payload });
                    }
                    const takePayload = {
                        video_path: assets['processing_veneer_thumb_path'], 
                        thumbnail:  assets['processing_veneer_thumb_gif'], // assets['processing_veneer_thumb'],
                        shot_id: veneer.shot_id,
                        take_id: veneer_id,
                    }
                    dispatch({ type: "ADD_TAKE_TO_SHOT", payload: takePayload })
                    const shotPayload = {
                        take_id: veneer_id, 
                        // uploader_id: "veneer", // msg.input.user_id,   // we did this when it was in the cloud  ... now its a name, so autoveneer [disabled] might apply to veneer as well?
                        // media_source: "veneer", // ToDo:  this for upload & capture too, also yt
                        image: assets['processing_veneer_thumb_jpg'],
                        stream_path:  assets['processing_veneer_streampath'], 
                        shot_id: veneer.shot_id
                    }; 
                    dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: shotPayload })
                    state.app.userIsStoryOwner ? logEvent(analytics, 'OWNER_VENEER') : logEvent(analytics, 'COMMUNITY_VENEER')
                })
                .catch((error) => {
                    console.log("Error creating veneer request:", veneer);
                });
                return draft

            case "UPDATE_STORY_SHOT_TITLE":
                const { shot_id, text } = action.payload;
                if (shot_id && text !== undefined) {
                    draft.story.shots.forEach(shot => {
                        if (shot.id === shot_id) {
                            shot.header = text;
                        }
                    });
            
                    setDoc(shotRef, { header: text }, {merge:true})
                }
                //         .then(() => {
                //             // console.log("Document successfully updated!");
                //         })
                //         .catch(error => {
                //             // console.error("Error updating document: ", error);
                //         });
                // } else {
                //     // console.error("Invalid shot_id or text:", shot_id, text);
                // }
            
                return draft
            
             
            
 

            case "UPDATE_STORY_SHOT_PROMPT":
                setDoc(shotRef, {"prompt": action.payload.prompt}, {merge:true})
                return draft

            case "UPDATE_USER_CREDITS":
                if(!currentUser) return draft;
                const userRef = doc(db, 'users', currentUser.uid);
                // note:  we don't have a listener in the app, so potential future cloud billing might be wiped out by next user puchase
                draft.userData.credits = action.payload.credits
                setDoc(userRef, {"credits": action.payload.credits, "updated_at": serverTimestamp()}, {merge:true})
                return draft

            case "UPDATE_STORY_SHOT_IMAGE":
                // function replaceImageIfIdMatches(shot, payload) {
                //     if (shot.id === payload.shot_id) {shot.image = payload.image}
                // }
                // draft.story.shots.map(shot => replaceImageIfIdMatches(shot,action.payload))
                setDoc(shotRef, {"image": action.payload.image}, {merge:true})

                return draft
            /*case "UPDATE_SHOT_SELECTED_TAKE_IM": // possibly depricate in favor of UPDATE_SHOT_SELECTED_TAKE_IM
                setDoc(shotRef, {"last_take_im": action.payload.image}) // this was working

                return draft
            case "UPDATE_SHOT_EMPTY_TAKE":
                    setDoc(shotRef, {"selected_take_id": null, "last_take_id": null, "last_take_im": null, "last_take_stream_url": null, "image": action.payload.image}) // proposed new
                    // setDoc(shotRef, {"selected_take_im": action.payload.image})  maybe we add this logic ... selected_take_id
    
                    return draft
            */

           case "UPDATE_SHOT_LAST_TAKE":
                //const shotRef = doc(storyRef, 'shots', action.payload.shot_id)
                setDoc(shotRef, {"last_take_id": action.payload.take_id, "last_take_im": action.payload.image, "last_take_stream_url": action.payload.stream_path}, {merge:true}) // proposed new
                .catch((error) => {
                    console.log('Error updating shot last take:', shotRef)
                })
                return draft

            case "UPDATE_SHOT_SELECTED_TAKE":
                //const shotRef = doc(storyRef, 'shots', action.payload.shot_id)
                setDoc(shotRef, {"selected_take_id": (action.payload.image) ? action.payload.take_id : null, "selected_take_im": action.payload.image, "selected_take_stream_url": action.payload.stream_path}, {merge:true}) // proposed new
                // setDoc(shotRef, {"selected_take_im": action.payload.image})  maybe we add this logic ... selected_take_id
                return draft

            case "FORK_STORY": 
                draft.app.forkingStory = action.payload
                return draft;
            
            case "CLEAR_FORK": 
                draft.app.forkingStory = null;
                return draft;

            case "STORY_IMPORT":
                if(!currentUser) return draft;
                const importPayload = action.payload;
                let importStoryRef = action.payload.storyRef;
                const bw = draft.app.settings.proxyIsBlackAndWhite;
                console.log("BW", bw);
                const handleStory = () => {
                    console.dir("IMPORTREF", importStoryRef)
                    console.dir("PAYLOAD", importPayload);
                    const uploader_id = (importPayload.currentUser) ? importPayload.currentUser.uid : "anon"
                    console.dir("UPLOADER", uploader_id)
                    console.log(serverTimestamp());
                    const vid = {video_path: importPayload.video_path, 
                        thumbnail: importPayload.thumbnail,
                        id: importPayload.id,
                        type: "storyboard", 
                        uploaded_at: serverTimestamp(),
                        //modified_at: Date.now(), 
                        uploader_id: uploader_id,
                        proxyLUT : bw ? "gray_strong_contrast" : "none",
                        deleted_at: null}; 
                    console.dir("VID", vid);
                    const vidRef = doc(importStoryRef, 'imports', importPayload.id)
                    console.dir("id", importPayload.id);
                    setDoc(vidRef, vid, {merge:true})
                        .then(() => {
                            vidRef;
                            console.dir("VID REF", vidRef)
                            logEvent(analytics, 'NEW_AUTOSTORY')
                        })
                    .catch((error) => {
                        console.log('Error importing scenes:', importStoryRef)
                    })
                    //console.log("ADD_TAKE_TO_SHOT  cloud ", payload.take_id)
                    
                }
                /*const getStoryRef = async () => {
                    const userRef = doc(db, 'users', currentUser.uid);
                    console.dir("GET PORTALS", doc(db, 'portals', action.payload.portalId))
                    const portalRef = doc(db, 'portals', action.payload.portalId)
                    await getDoc(portalRef).then(async (pRef) => {
                        console.dir("portal", pRef.data())
                        if (pRef.exists()) {
                            //console.log("PORTAL data:", doc.data());  //defeats security if we just log it out, lol
                            const data = pRef.data();
                            const user_id = data.creator_id
                            const story_id = data.story_id
                            importStoryRef = doc(userRef, 'stories', story_id);
                            handleStory();
                        }
                    });
                }*/
                
                /*if(!importStoryRef && action.payload.portalId) {
                    getStoryRef();
                } else {
                    handleStory();
                }*/
               handleStory();

                return draft;

            case "ADD_TAKE_TO_SHOT":
                // push take to the cloud under SHOT
                const payload = action.payload;
                
                //const activeStoryRef = draft.stories.activeStoryRef   // this gets cleared
                if (storyRef) {
                    const uploader_id = (currentUser) ? currentUser.uid : "anon"
                    const take = {video_path: payload.video_path, 
                        thumbnail: payload.thumbnail,
                        id: payload.take_id,
                        shot_id: payload.shot_id,
                        uploaded_at: serverTimestamp(),
                        //modified_at: Date.now(), 
                        uploader_id: uploader_id,
                        proxyLUT : draft.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                        deleted_at: null}; 
                    
                    
                        //dur_ms
                        //est_created_time - from filename? or
                        //  // payload.user_id  .. why are these undefined!?  vs e.g. ahbMAd47J1aWnEhDrq5PwjQSmBU2


                    // I get the sense that this may be the way to go for large projects, with GET instead of snapshot
                    // but having N listeners for each participant seems overkill. 
                    //    this code remains here so it could be reanble for testing at a future date
                    //    As of Sept 2021 - Mar 2022 (including okapi-webdev data), this was mirrored in both places.
                    //    This state is not properly maintained for subsequent changes, like thumbnail updates, etc.
                    //  In Mar 2022, including all of 10zebra data, this was depricated.
                    //
                    // const takeRefALT = storyRef.collection('shots').doc(payload.shot_id).collection('takes').doc(payload.take_id)
                    // takeRefALT.set(take)
                    //    .then(() => takeRefALT)
                    // .catch((error) => {
                    //   console.log('Error creating new take:', takeRefALT)
                    // })

                    // we are actually using these takes for rendering the active state in the app
                    const takeRef = doc(storyRef, 'takes', payload.take_id)
                    setDoc(takeRef, take, {merge:true})
                        .then(() => takeRef)
                    .catch((error) => {
                        console.log('Error creating new take:', takeRef)
                    })
                    //console.log("ADD_TAKE_TO_SHOT  cloud ", payload.take_id)

                } else {
                    console.log("WARNING! Cant add take to story", draft.stories.activeStory)
                }

                // function addTakeIfIdMatches(shot, payload) {
                //     if (shot.id === payload.shot_id) {
                //         shot.takes = shot.takes || [];
                //         shot.takes.push({video_path: payload.video_path, thumbnail: payload.thumbnail})
                //     }
                // }
                // draft.story.shots.map(shot => addTakeIfIdMatches(shot,action.payload))
                return draft

            case "ADD_IMAGE_TO_SHOT":
                // push image to the cloud, we will treat it as a video with 1 second log, plus some meta data
                const im_payload = action.payload;
                if (storyRef) {
                    const uploader_id = (currentUser) ? currentUser.uid : "anon"
                    const take = {image_path: im_payload.image_path, 
                        // video_path: payload.video_path,  // should we add an loading video until we replace it?
                        thumbnail: im_payload.thumbnail,
                        id: im_payload.take_id,
                        shot_id: im_payload.shot_id,
                        uploaded_at: serverTimestamp(),
                        //modified_at: Date.now(), 
                        uploader_id: uploader_id,
                        proxyLUT : draft.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                        deleted_at: null}; 
                    
                const takeRef = doc(storyRef, 'takes', im_payload.take_id)
                setDoc(takeRef, take, {merge:true})
                    .then(() => takeRef)
                .catch((error) => {
                    console.log('Error creating new take:', takeRef)
                })
                } else {
                    console.log("WARNING! Cant add image take to story", draft.stories.activeStory)
                }

                return draft
            case "MOVE_TAKE_TO_SHOT":

                const destShotRef = ("payload" in action && "dest_shot_id" in action.payload) ? doc(storyRef, 'shots', action.payload.dest_shot_id) : null
                //const oldShotRef = ("payload" in action && "shot_id" in action.payload) ? storyRef.collection('shots').doc(action.payload.shot_id) : null

                setDoc(takeRef, {"shot_id": action.payload.dest_shot_id, "modified_at": serverTimestamp()}, {merge:true})
                
                setDoc(destShotRef, {"last_take_id": action.payload.take_id, "last_take_im": action.payload.image, "last_take_stream_url": action.payload.stream_path}, {merge:true})
// if dest shot has selected shot
                var shot_payload = { }
                if (action.payload.prev_selected_take_id == action.payload.take_id) {
                    shot_payload["selected_take_id"] = null
                    shot_payload["selected_take_stream_url"] = null
                    shot_payload["selected_take_im"] = null
                }
                if (action.payload.prev_last_take_id == action.payload.take_id) {
                    shot_payload["last_take_stream_url"] = null
                    shot_payload["last_take_im"] = null
                    shot_payload["last_take_id"] = null
                }
                if (Object.keys(shot_payload).length > 0) { 
                    setDoc(shotRef, shot_payload, {merge:true})
                }

                return draft
            // case "UPDATE_NUM_SHOTS":
            //     draft.story.num_shots = action.payload.num_shots;
            //     return draft;
            case "RESET_STATE":
                draft = initialState;
                return draft;
            
            case "DO_USER_INIT":
                doUserInit();
                return;
            
            case "SAVE_CUT":
                draft.app.savingCut = action.payload;
                return draft;

            case "CLEAR_LAST_CUT":
                draft.stories.activeStory.last_cut = null
                // storyRef.update{}
                return draft;
            case "CLEAR_SAVE_CUT":
                draft.app.savingCut = null
                // storyRef.update{}
                return draft;
            case "CLEAR_LAST_EXPORT":
                draft.stories.activeStory.last_export = null
                return draft;
            case "APP_LOADED":
                draft.app.isLoading = false;
                return draft;
            case "ADD_ALERT": // Add alert to the list of active alerts
                // type: success, info, warning, error
                // message: string
                draft.app.alerts[Date.now()] = action.payload.alert;
                return draft;
            case "CLEAR_ALERT":
                delete draft.app.alerts[action.payload.key];
                return draft;
            case "MODAL":  // Set open/closed draft of App dialogs
                draft.app.modal = action.payload;
                return draft;
            case "CONTROL":  // Top level draft of app controls
                draft.app.controls[action.payload.name] = action.payload.state;
                return draft;
            case "RESET_FLIP":  // Reset flip initiator
                draft.app.controls.flip = false;
                return draft;
            case "FLIP_SHOTS":  // Initiate card flip sequence
                draft.app.controls.flip = true;
                return draft;
            case "SET_PREVIEW_IMAGE":  // Insert the preview into draft.cardPreviews with the cardID as the key
                // args: cardID, src
                {
                    let preview = {
                        src: action.payload.src,
                        orientation: action.payload.orientation,
                    }
                    draft.app.cardPreviews[action.payload.cardID] = preview;
                    return draft;
                }
            case "CLEAR_PREVIEW_IMAGE":  // Delete the image keyed off the cardID
                // args: cardID
                delete draft.app.cardPreviews[action.payload.cardID]
                return draft;
            case "START_UPLOAD":  // Configure and open upload modal
                logEvent(analytics, 'START_UPLOAD'); 
                const upload = {
                    data: {
                        totalFiles: action.payload.totalFiles,
                        totalBytes: action.payload.totalBytes,
                        uploadCount: 0,
                        failedCount: 0,
                        failedNames: [],
                        fileBytes: {},
                    }
                }
                draft.app.upload = upload;
                return draft;
            case "FILE_UPLOAD_UPDATE": {
                if (draft.app.upload) {
                    draft.app.upload.data.fileBytes[action.payload.cardID] = action.payload.bytes;
                }
                return draft;
            }
            case "FILE_UPLOAD_SUCCESS":  // Register one successful file uploaded
                if (draft.app.upload) {
                    draft.app.upload.data.uploadCount++;
                    draft.app.contributedCount = draft.app.contributedCount + draft.app.upload.data.totalFiles;
                    logEvent(analytics, 'FILE_UPLOAD_SUCCESS'); // , { count: draft.app.contributedCount});
                    // TODO: Clean up cards for failed uploads and notify the user
                    // Even if they have a preview image, they need an original image to be valid
                    if (draft.app.upload.data.uploadCount + draft.app.upload.data.failedCount === draft.app.upload.data.totalFiles) {
                        draft.app.upload = null
                    }
                }
                return draft;
            case "FILE_UPLOAD_FAILURE":  // Register a failed file upload
                // args: fileName
                if (draft.app.upload) {
                    draft.app.alerts[Date.now()] = {
                        type: 'error',
                        message: `Upload failed for ${action.payload.fileName}`,
                    }
                    draft.app.upload.data.failedCount++;
                    draft.app.upload.data.failedNames.push(action.payload.fileName)
                    logEvent(analytics, 'FILE_UPLOAD_FAILURE') //, { filename: action.payload.fileName}); 

                    // Even if they have a preview image, they need an original image to be valid
                    if (draft.app.upload.data.uploadCount + draft.app.upload.data.failedCount === draft.app.upload.data.totalFiles) {
                        // TODO: Open file failure modal to show names and let the user confirm
                        draft.app.upload = null
                    }
                } else {
                    console.error('Attempt to register file upload failure but file upload modal is not open.')
                }
                return draft;
            case "START_IMPORT":
                logEvent(analytics, 'START_IMPORT'); 
                const importing = {  // could update to frames processed ... not really required. 
                    data: {
                        totalFrames: 100,
                        processedFrames: 10,
                    }
                }
                draft.app.importing = importing;   
                return draft;
            case "IMPORT_SUCCESS":  
                // this just ends alert for now
                if (draft.app.importing) {
                    draft.app.importing = null
                }
                return draft;
            case "UPDATE_PORTAL":
                draft.app.portal.id = action.payload.portal_id;
                return draft; 
            case "UPDATE_ACTIVE_SHOT":
                //draft.stories.activeShotID = action.payload.shot_id;
                return draft; 
            case "ADD_REQUESTED_PORTAL":
                draft.app.portal.requested_id = action.payload.portal_id;
                return draft; 
            case "CLEAR_REQUESTED_PORTAL":
                draft.app.portal.requested_id = null;
                return draft; 
            case "UPDATE_ACTIVE_STORY_REF":
                draft.stories.activeStoryRef = action.payload.activeStoryRef;
                return draft;
            case "CLEAR_ACTIVE_STORY_REF":
                draft.stories.activeStoryRef = null;
                draft.stories.activeStory = {}
                draft.stories.activeShotList = [];
                draft.stories.activeTakeList = [];
                return draft;
            case "CLEAR_ACTIVE_ALBUM_REF":
                draft.inspiration.activeAlbumRef = null;
                draft.inspiration.activeAlbum = {}
                draft.inspiration.activeAlbumCardList = [];
                return draft;
            case "UPDATE_USER_DATA":
                draft.userData = action.payload.userData;
                return draft;
            case "UPDATE_STORY_LIST":
                draft.stories.storyList = action.payload.storyList;
                return draft;
            case "UPDATE_SHARED_STORY_LIST":
                draft.stories.sharedStoryList = action.payload.sharedStoryList;
                return draft;
            case "NEW_ACTIVE_STORY":
                draft.stories.nextStoryMetadata = action.payload.newStory
                //draft.stories.activeStory = action.payload.newStory;
                return draft;
            case "CLEAR_NEXT_STORY_METADATA":
                draft.stories.nextStoryMetadata = null
                return draft;
            case "UPDATE_ACTIVE_STORY":
                console.log("update active story")
                draft.stories.activeStory = action.payload.activeStory;
                draft.app.userIsStoryOwner = false
                return draft;
            case 'SET_USER_IS_STORY_OWNER':
                console.log("set user is story owner")
                draft.app.userIsStoryOwner = action.payload.userIsStoryOwner;
                return draft;
            case "UPDATE_ACTIVE_SHOT_LIST":
                draft.stories.activeShotList = action.payload.activeShotList;
                // console.log("UPDATE_ACTIVE_SHOT_LIST", action.payload.activeShotList)
                return draft;
            case "UPDATE_ACTIVE_TAKE_LIST":
                draft.stories.activeTakeList = action.payload.activeTakeList;
                return draft;
            case "UPDATE_ACTIVE_VENEER_LUT":
                const VeneerLUT = {};
                action.payload.activeVeneerList.forEach((vn) => {
                    VeneerLUT[vn.ref.id] = vn
                })
                draft.stories.activeVeneerLUT = VeneerLUT
                return draft;
            case "UPDATE_STORY_SETTINGS":
                let new_settings = draft.app.settings
                for (const key in action.payload.settings) {
                    new_settings[key] = action.payload.settings[key]
                }
                draft.app.settings = new_settings
                return draft
            case "UPDATE_PRIORITIZE_LIVE_PREVIEW":
                draft.app.prioritizeLivePreview = action.payload.prioritizeLivePreview;
                return draft
            case "UPDATE_ALBUM_SOURCE_SET":
                const newSourceSet = new Set();
                action.payload.sourceSet.forEach((sourceID) => {
                    newSourceSet.add(sourceID)
                })
                draft.stories.albumSourceSet = newSourceSet;
                return draft;
            case "UPDATE_ACTIVE_ALBUM_SHOT_LIST":
                draft.inspiration.activeAlbumCardList = action.payload.activeAlbumCardList;
                return draft;
            case "UPDATE_ALBUM_LIST":
                draft.inspiration.albumList = action.payload.albumList;
                return draft;
            case "UPDATE_ACTIVE_ALBUM_REF":
                draft.inspiration.activeAlbumRef = action.payload.activeAlbumRef;
                return draft;
            case "UPDATE_ACTIVE_ALBUM":
                draft.inspiration.activeAlbum = action.payload.activeAlbum;
                return draft;
            case "SET_STARRED_STORIES": 
                draft.stories.starredStories = action.payload.starredStories;
                return draft;
            case "GENERATE_STORY": 
                draft.stories.generateStory = action.payload.generateStory;
                return draft;
            case "FORK_FROM_PORTAL":
                console.dir("payload", action.payload);
                draft.app.initAndFork = {portalId: action.payload.portalId, fork: action.payload.fork}
                return draft;
            case "CLEAR_INIT_AND_FORK":
                draft.app.initAndFork = null;
                return draft;
            default:
                return draft;
        }
    };
    const [state, dispatch] = useImmerReducer(reducer, initialState);

    // RESET STATE
    // When the user changes, reset the state 
    useEffect(() => {
        if (!currentUser || !state.userData.id) {
            dispatch({ type: "RESET_STATE" })
            return
        }
        if (state.userData.id !== currentUser.uid) {
            dispatch({ type: "RESET_STATE" })
            doUserInit();
        }
    }, [dispatch, currentUser, state.userData?.id]);


    // define some async functions that are used in multiple Effacts: initial user setup and new project creation

    useEffect(() => {
        if (!currentUser) return;
        let unSubscribe = () => { };
        unSubscribe = onSnapshot(collection(db, 'users', currentUser.uid, 'starred_portals'), 
            (snapshot => {
                const starredStories = snapshot.docs.map(doc => doc.id);
                dispatch({ type: "SET_STARRED_STORIES", payload: { starredStories } });
            }));
        return () => unSubscribe();
    }, [currentUser]);

    const setupInitialStory = async (userRef) => {
        const storyRef = doc(userRef, 'stories');
        const newStory = initialStory; // storyConstructor();

        newStory.shots.forEach((shot, key) => {
            console.log("INITIALIZE init SHOT", shot.id)
            const shotRef = doc(storyRef, 'shots', shot.id);
            setDoc(shotRef, shot, {merge:true})
                .then(() => shotRef)
            .catch((error) => {
                console.log('Error creating new shot:', shotRef)
            })
        })

        return setDoc(storyRef, newStory, {merge:true})
            .then(() => storyRef)
            .catch((error) => {
                console.log('Error creating new story for user:', userRef.id)
                return error
            })
    }

    const setupNewUser = async (user) => {
        const storyId = nanoid(20);
        const name = user.displayName || "";
        const newUser = {
            name: user.displayName,
            email: user.email,
            account: "free",
            credits: 250,
            //lastActiveStory: storyRef,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            deleted_at: null,
        }
        const addUser = async () => {
            const userRef = doc(db, 'users', user.uid);
            await setDoc(userRef, newUser, {merge:true})
            const userDoc = await getDoc(userRef)
            if(userDoc.exists()) {
                const data = userDoc.data();
                dispatch({ type: "UPDATE_USER_DATA", payload: { data } });
            }
            
        }
        addUser();
    }

    const doUserInit = async (do_active_story_init) => {
        if (!currentUser) { return }
        console.dir("current user", currentUser)
            let unSubscribe = () => { };
            try {
                const userRef = doc(db, 'users', currentUser.uid);
                console.dir("do user init", userRef)
                getDoc(userRef).then(async uDoc => {
                    if (uDoc.exists()) {
                        let userData = uDoc.data();
                        console.dir("uDoc", userData)
                        //if(Object.keys(userData.ref).length) return
                        userData.ref = uDoc;
                        userData.superuser = false;
                        userData.zebradmin = false;
                        userData.photoURL = currentUser.photoURL;
                        if (userData.email === 'pmm3123@gmail.com') {
                            userData.superuser = true;
                        }
                        if (!userData.account) {
                            // old accounts are set to free and 100 credits
                            userData.account = "free";
                        } 
                        if(userData.email.indexOf("@10zebra.com") != -1 || userData.email.indexOf("@apollofractional.com") != -1) {
                            userData.zebradmin = true;
                            if (userData.credits < 50) {
                                userData.credits = 500;
                            }
                        }
                        if (do_active_story_init && userData.lastActiveStory) {
                            console.log("init story ref")
                            const initStoryRef = async () => {
                                const arr = userData.lastActiveStory.split("/")
                                await initializeActiveStoryRef(userRef, doc(userRef, "stories", arr.pop()), state.app.portal)
                            }
                            initStoryRef();
                        }
                        dispatch({ type: "UPDATE_USER_DATA", payload: { userData } });
                    } else {
                        setupNewUser(currentUser)
                    }
                })
            } catch (error) {
                console.log('Error subscribing to updates for user data: ', error)
            } finally {
                console.log("unsubscribe")
                return () => unSubscribe();
            }
    }

    const initializeActiveStoryRef = async (userRef, storyRef, portal) => {
        let activeStoryRef = storyRef //doc(db, storyRef);
        /*if (storyRef) {
            // Check if story exists 
            await getDoc(doc(db, storyRef)).then(sDoc => {
                if(sDoc.exists()) activeStoryRef = sDoc
            });
        }*/
        // If not, check if there are other stories to set it to and set it to the first one
        if (!storyRef) {
            // The original activeStory no longer exists, so check fo any other stories
            const querySnapshot = await getDocs(query(collection(userRef, 'stories'), orderBy("date", "desc"), limit(1))); 
            activeStoryRef = (querySnapshot.exists()) ? querySnapshot.docs[0].ref :null;
        }

        // If no stories, create one and set it
        if (!activeStoryRef) {
            await setupInitialStory(userRef)
                .then((newStoryRef) => activeStoryRef = newStoryRef)
                .catch((error) => {
                    console.log('Unable to initialize activeStoryRef. Creating new story failed.', error)
                })
        }

        if (activeStoryRef) {
            console.dir(activeStoryRef);
            dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { activeStoryRef: activeStoryRef } });
            await setDoc(userRef, { "lastActiveStory": activeStoryRef.path }, {merge:true})
                .catch((error) => {
                    console.error('Error writing activeStoryRef to user document in database: ', error)
                })
        }  
    }

    const initializeFromPortal = async (portal_id, fork) => {
        console.dir("CURRENT USER", currentUser);
        console.log('would do portal', portal_id )
        if(!currentUser) return;
        const portalRef = doc(db, 'portals', portal_id) 
        const userRef = doc(db, 'users', currentUser.uid);
        try {
            await getDoc(portalRef).then((pRef) => {
                console.dir("portal", pRef.data())
                if (pRef.exists()) {
                    //console.log("PORTAL data:", doc.data());  //defeats security if we just log it out, lol
                    const data = pRef.data();
                    const user_id = data.creator_id
                    const story_id = data.story_id
                    console.log("----initialize portal")
                    console.dir("data", data)
                    let unSubscribe = () => { };
                    try {    
                        const storyRef = doc(db, 'users', user_id, 'stories', story_id);
                        if(!storyRef) return
                        unSubscribe = onSnapshot(storyRef, async snapDoc => { // db.database().ref('portal/' + portal_id + '/story_path');
                            if (snapDoc.exists() && !snapDoc.data().deleted_at/*qDoc.exists && !qDoc.get("deleted_at")*/) {
                                const activeStoryRef = snapDoc.ref
                                if(!fork) dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { 'activeStoryRef': activeStoryRef } });
                                //state.app.userIsStoryOwner ? logEvent(analytics, 'OWNER_FORK') : logEvent(analytics, 'COMMUNITY_FORK')
                                else {
                                    const data = snapDoc.data();
                                    let forkObj = structuredClone(fork); 
                                    forkObj.parts = data.parts;
                                    forkObj.description = data.description;
                                    if ("last_audio" in data) {
                                        forkObj["last_audio"] = data.last_audio;
                                    }
                                    console.dir("user ref", userRef)
                                    console.dir(storyRef.path)
                                    await setDoc(userRef, {"lastActiveStory": storyRef.path}, {merge:true}).then(() => {
                                        console.dir("FORK", forkObj);
                                        //if (!userRef) {console.log(""); return} else {console.log("FORK USER ", userRef.id)}
                                        //if (!storyRef) {console.log("FORK RETURN NO STORY"); return} else {console.log("FORK STORY", storyRef.id)}
                                        
                                        //dispatch({ type: "SET_ACTIVE_FROM_PORTAL", {portalId:storyId, fork:newStory}})
                                        dispatch({ type: "FORK_STORY", payload: {forkingStory: forkObj, mediaOption: "All Media", userRef: userRef, storyRef: storyRef, newStory: forkObj }});
                                    });
                                }
                            } else {
                                console.log("Story? No such document!");
                                //dispatch({ type: "CLEAR_ACTIVE_STORY_REF" });
                            }
                        });
                    }
                    catch (error) {
                        console.log('Error querying data for portal defined Story: ', error)
                    } finally {
                        return () => unSubscribe();
                    }

                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
        } catch(error) {
                console.log("Error getting document:", error);
        };
    }

    // USER DATA
    // Listen for updates to userData and initialize active story
    // If no user data, set up new user and new story
    useEffect(() => {
        const loc = window.location;  //  note:  useParams *should* work here, but doesn't, so we parse the URL for portal id
        let path = matchPath(loc.pathname, {
            path: '/p/:id/:shotID', 
            exact: false, 
            string: false
        })
        if(!path) {
            path = matchPath(loc.pathname, {
                path: '/p/:id', 
                exact: false, 
                string: false
            })
        }

        let do_user_init = true
        let do_active_story_init = true
        let portal_id = "non-portal"
        let shot_id = null
        console.dir("current user", currentUser)
        if (!state.app.portal.id) {
            // A null id will get initialized to the portal id in the URL
            if (path != null && path?.params?.id){
                portal_id = path.params.id //(loc.pathname.match('/p/*')[0] === '/p/') ? loc.pathname.substring(3) : "non-portal"     
                console.log("portal: " +portal_id)   
                do_user_init = false
                do_active_story_init = false
                dispatch({ type: "UPDATE_PORTAL", payload: { portal_id } }); 
            } else {
                portal_id = "non-portal"
            }
        } else if(!Object.keys(state.userData).length) {
            portal_id = state.app.portal.id
            do_user_init = (currentUser) ? true : false
            do_active_story_init = false
        } else {
            console.log('PORTAL_ID @ init', state.app.portal)
            do_user_init = false
            do_active_story_init = false
            portal_id = state.app.portal.id
        }

        if (do_user_init) {
            console.dir("do_user_init", portal_id)
            doUserInit(do_active_story_init);
        }     
        else {
            const init = async () => {
                await initializeFromPortal(portal_id)
            }
            init();
        }
        
    }, [dispatch, currentUser, state.app.portal])


    // Make a new story out of next story
    // Next story is locally in the app.   It propagates out to the cloud and back. 
    useEffect(() => {
        if (!state.stories.nextStoryMetadata) return;
        const newStory = state.stories.nextStoryMetadata
        const userRef = doc(db, 'users', currentUser.uid);
        const newStoryId = nanoid(20);
        const generateStory = async (storyRef) => {
            if(!state.stories.generateStory) return;
            const composeId = nanoid(20);
            const compRef = doc(storyRef, 'compose', composeId);
            console.dir("userRef", userRef)
            await setDoc(userRef, {credits: increment(-state.stories.generateStory.cost)}, {merge:true}).then(async ()=>{
                await setDoc(compRef, state.stories.generateStory, {merge:true}).then(() => {
                    dispatch({ type: "GENERATE_STORY", payload: { "generateStory": null } });
                }).catch((error) => {
                    console.log('Error adding new compose for user: ', userRef.id, error)
                })
            });
        }
        const createStory = async() => {
            console.dir("NEW STORY", newStory);
            await setDoc(doc(userRef, 'stories', newStoryId), newStory, {merge:true});
            const newStoryRef = doc(userRef, 'stories', newStoryId);
            const newShotRef = collection(userRef, 'stories', newStoryId, 'shots')
            if(newStory.shots.length) {
                newStory.shots.forEach(async (shot, key) => {
                    await setDoc(doc(newShotRef, shot.id), shot, {merge:true})
                })
            }
            setDoc(userRef, {"lastActiveStory": newStoryRef.path}, {merge:true});
            setDoc(newStoryRef, newStory, {merge:true})
            const portalRef = doc(db, 'portals', newStory.portal_id);
            const newPortal = {creator_id: currentUser.uid, 
                story_id: newStoryId,
                created_at: serverTimestamp(), 
                deleted_at: null
            }
            setDoc(portalRef, newPortal, {merge:true})
            .then(() => {
                logEvent(analytics, 'NEW_STORY')
                initializeActiveStoryRef(userRef, newStoryRef, state.app.portal).then(() => {
                    dispatch({ type: "CLEAR_NEXT_STORY_METADATA"});
                    console.dir("generate", newStoryRef)
                    if(state.stories.generateStory) generateStory(newStoryRef)
                })
            }).catch((error) => {
                console.error('Error creating new story: ', error)
            })
        } 
        createStory();
    }, [state.stories.nextStoryMetadata])

    // Fork Story
    useEffect(() => {
        const forkIt = async () => {
            const { forkingStory, mediaOption, userRef, newStory, storyRef } = state.app.forkingStory;
            const storyId = nanoid()
            console.dir("NEW STORY", newStory);
            let story, shots, takes;
            if(forkingStory) {
                story = forkingStory;
                console.dir("----1")
                const shotSnapshot = await getDocs(query(collection(storyRef, 'shots'), where('deleted_at', '==', null)))
                const activeShotList = [];
                shotSnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        var shot = doc.data();
                        shot.ref = doc.ref;
                        activeShotList.push(shot)
                    }
                })
                console.dir(shotSnapshot)
                shots = activeShotList;
                console.dir(shots);
                const takeSnapshot = await getDocs(query(collection(storyRef, 'takes'), where('deleted_at', '==', null)));
                console.dir("TAKES", takeSnapshot)
                const activeTakeList = [];
                takeSnapshot.forEach(snapDoc => {
                    if (snapDoc.exists()) {
                        var take = snapDoc.data();
                        take.ref = snapDoc.ref;
                        activeTakeList.push(take)
                    }
                })
                takes = activeTakeList;
            } else {
                story = state.stories.activeStory;
                shots = state.stories.activeShotList;
                takes = state.stories.activeTakeList;
            }
            const portalId = newStory.portal_id;        
            const portalRef = doc(db, 'portals', portalId)
            console.dir("PORTAL", portalRef)
            const forkBatch = writeBatch(db);
            forkBatch.set(portalRef, {
                creator_id: state.userData.ref.id,
                story_id: storyId,
                created_at: serverTimestamp()
            }, {merge:true});
            
            const forkStory = async () => {
                //const uRef = getDoc(userRef);
                console.dir("! @ #")
                console.dir(userRef)
                const newStoryRef = doc(userRef, 'stories', storyId)
                console.dir(newStoryRef);
                forkBatch.set(newStoryRef, newStory, {merge:true})
                console.dir("#2")
                forkBatch.set(newStoryRef, {'ref': newStoryRef}, {merge:true})
                console.dir("#3")
                dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { activeStoryRef: newStoryRef } });
                console.dir("STORY", story)
                const shotPromises = story.parts.flatMap(part =>
                    part.order.map(async (shot_id) => {
                        const this_shot = shots.filter((shot) => shot.id == shot_id)[0]
                        if (!this_shot || this_shot.deleted_at) {
                            console.log(`Shot not copied (deleted or not found) for ID: ${shot_id}`);
                            return;
                        }
                        const newShotRef = doc(newStoryRef, 'shots', shot_id)
                        let image = this_shot.image
                        let last_take_stream_url = null
            
                        if (this_shot.last_take_im) {
                            image = this_shot.last_take_im
                        }
                        if (this_shot.selected_take_id) {
                            const this_take_list = takes.filter((take) => take.id == this_shot.selected_take_id);
                            const this_take = this_take_list[0];
                            if (this_take) {
                                image = this_take.thumbnail;
                            }
                        }
            
                        let prompt = this_shot.prompt || "";
            
                        if (this_shot.selected_take_id) {
                            const this_veneer = state.stories.activeVeneerLUT[this_shot.selected_take_id];
                            if (this_veneer) {
                                prompt = this_veneer.prompt;
                            }
                        }
            
                        const newShot = {id: shot_id, // the same shot id, but different story. could enable trackable rights and merging one day
                            header: this_shot.header,
                            image: image,
                            meta: "",
                            prompt: prompt,
                            description: this_shot.description,
                            last_take_id: (mediaOption === "No Media") ? null : this_shot.last_take_id || null,
                            selected_take_id: (mediaOption === "No Media") ? null : this_shot.selected_take_id || null,
                            last_take_im: (mediaOption === "No Media") ? null : this_shot.last_take_im || null,
                            last_take_stream_url: (mediaOption === "No Media") ? null : last_take_stream_url,
                            deleted_at: null
                        };
                        // here we explicity add the fields ... in the future consider itter over all items and set the 4 above to null.  
                        if ("tgt_stream_url" in this_shot) {
                            newShot["tgt_stream_url"] = this_shot.tgt_stream_url;
                        }
                        if ("tgt_dur_ms" in this_shot) {
                            newShot["tgt_dur_ms"] = this_shot.tgt_dur_ms;
                        }
                        console.dir("set doc", newShot);
                        setDoc(newShotRef, newShot, {merge:true});
                    })
                );

                await Promise.all(shotPromises); 
                // Copy settings
                try {
                    const settingsDoc = await getDoc(doc(storyRef, 'settings', '0'));
                    if (settingsDoc.exists) {
                        const settingsData = settingsDoc.data();
                        const newSettingsRef = doc(newStoryRef, 'settings', '0');
                        forkBatch.set(newSettingsRef, settingsData); // Copy the settings into the new story
                        console.log("Settings copied successfully.");
                    } else {
                        console.log("No settings found to copy.");
                    }
                } catch (error) {
                    console.error("Error fetching settings: ", error);
                }
                if (mediaOption === "All Media") {
                    for (let take of takes) {
                    // Fallback to empty string if undefined & set empty ""
                        if (take.deleted_at) {
                            console.log(`Take not copied (deleted) for ID: ${takeData.id}`);
                            return;
                        }
                        const newTake = {
                            video_path: take.video_path || "", 
                            thumbnail: take.thumbnail || "", 
                            id: take.id,
                            shot_id: take.shot_id || "", 
                            uploaded_at: take.uploaded_at || "", 
                            uploader_id: take.uploader_id || "", 
                            proxyLUT: state.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                            deleted_at: null
                        };
                        const newTakeRef = doc(newStoryRef, 'takes', take.id);
                        forkBatch.set(newTakeRef, newTake, {merge:true});
                        // Copy veneers
                        const veneer = state.stories.activeVeneerLUT[take.id];
                        if (veneer) {
                            const newVeneerRef = doc(newStoryRef, 'veneers', take.id);
                            const newVeneer = {
                                ...veneer,
                                deleted_at: null
                            };
                            forkBatch.set(newVeneerRef, newVeneer);
                        } else {
                            console.log(`No veneer found for take ID: ${take.id}`);
                        }
                    }
                } else if (mediaOption === "Selected Media") {
                    for (let shot of shots) {
                        const selectedTakes = takes.filter(take => take.id === (shot.selected_take_id || shot.last_take_id));
                        for (let take of selectedTakes) {
                            const newTake = {
                                video_path: take.video_path || "", 
                                thumbnail: take.thumbnail || "", 
                                id: take.id,
                                shot_id: take.shot_id || "", 
                                uploaded_at: take.uploaded_at || "", 
                                uploader_id: take.uploader_id || "",
                                proxyLUT: state.app.settings.proxyIsBlackAndWhite ? "gray_strong_contrast" : "none",
                                deleted_at: null
                            };
                            const newTakeRef = doc(newStoryRef, 'takes', take.id);
                            forkBatch.set(newTakeRef, newTake, {merge:true});
                            // Copy veneers
                            const veneer = state.stories.activeVeneerLUT[take.id];
                            if (veneer) {
                                const newVeneerRef = doc(newStoryRef, 'veneers', take.id);
                                const newVeneer = {
                                    ...veneer,
                                    deleted_at: null
                                };
                                forkBatch.set(newVeneerRef, newVeneer);
                            } else {
                                console.log(`No veneer found for take ID: ${take.id}`);
                            }
                        }
                    }
                }
                await forkBatch.commit();
            
                await setDoc(userRef, { "lastActiveStory": newStoryRef.path }, { merge:true }).then(() => {
                    const payload = {
                        activeStoryRef: newStoryRef, 
                    }
                    dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: payload });
                })
                .catch((error) => {
                    console.error('Error writing activeStoryRef to user document in database: ', error)
                })            
            }
            forkStory();
            /*const unsetFork = async () => {
                state.app.forkingStory = null;
                /*if (state.stories.activeStoryRef) {
                    return await setDoc(state.stories.activeStoryRef, {"fork_story": false}, {merge:true})
                }*
            }
            
            unsetFork()*/
        }
        if(state.app.forkingStory) forkIt();
    }, [state.app.forkingStory])

    // Init and Fork
    useEffect(() => {
        if(!state.app.initAndFork) return;
        initializeFromPortal(state.app.initAndFork.portalId, state.app.initAndFork.fork);
        dispatch({ type: "CLEAR_INIT_AND_FORK", payload: {} });
    }, [state.app.initAndFork]);

    // Save Cut
    useEffect(() => {
        if(state.app.savingCut) {
            const storyRef = state.stories.activeStory.ref;
            const { do_tgt_cut, sequence, tgt_audio} = state.app.savingCut;
            // settings for render
            const do_low_res = true;
            const do_high_res = true;
            // makes a zip archive at every save  ... its a bit slow, but we can render an export button when its ready   ... or we could do a popup alert message, only after a desired export action?
            const do_export = true;
            if (do_tgt_cut) {
                if (do_low_res) {
                  console.log("LOW RES tgt RENDER video_filenames:", sequence["proxy"]);
                  const cut_request_tgt = {
                    save_type: "tgt",
                    high_quality: "False",
                    video_filenames: sequence["proxy"],
                    audio: tgt_audio, // todo: get from import_media if it has audio? place here if there is only one chapter with clips in order?  story.last_audio
                    version: null,
                    stream_path: null,
                    thumbnail: null,
                    created_at: serverTimestamp(),
                    completed_at: null,
                    tgt: { media: sequence["tgt"] },
                  };
                  const cut_id_tgt = uuidv4();
                  const cutRefTgt = doc(storyRef, "cuts", cut_id_tgt)
                  setDoc(cutRefTgt, cut_request_tgt, {merge:true})
                    .then(() => {
                        dispatch({ type: "START_CUT", payload: {} });
                    })
                    .catch((error) => {
                      console.log("Error creating HQ cut request:", cut_request_tgt);
                    });
                  console.log("tried_cut tgt merge:", cut_request_tgt);
                }
        
                if (do_high_res) {
        
                  const cut_request_tgt = {
                    save_type: "tgt",
                    high_quality: "True",
                    video_filenames: sequence["files"],
                    audio: tgt_audio,
                    version: null,
                    stream_path: null,
                    thumbnail: null,
                    created_at: serverTimestamp(),
                    completed_at: null,
                    tgt: { media: sequence["tgt"] },
                  };
                  const cut_id_tgt = uuidv4();
                  const cutRefTgt = doc(storyRef, "cuts", cut_id_tgt)
                  setDoc(cutRefTgt, cut_request_tgt, {merge:true})
                    .then(() => {
                        dispatch({ type: "START_CUT", payload: {} });
                        logEvent(analytics, 'SAVE_CUT')
                    })
                    .catch((error) => {
                      console.log("Error creating HQ cut request:", cut_request_tgt);
                    });
                  console.log("tried_cut tgt merge:", cut_request_tgt);
        
                  if (do_export) {
                    const export_request_tgt = {
                      video_filenames: sequence["files"],
                      audio: tgt_audio,
                      version: null,
                      dst_zip_url: null,
                      created_at: serverTimestamp(),
                      tgt: { media: sequence["tgt"] },
                    };
                    const export_id_tgt = cut_id_tgt; // uuidv4()
                    const exportRefTgt = doc(storyRef, "exports", export_id_tgt)
                    setDoc(exportRefTgt, export_request_tgt, {merge:true})
                      .then(() => {
                        dispatch({ type: "START_EXPORT", payload: {} });
                      })
                      .catch((error) => {
                        console.log(
                          "Error creating export request:",
                          export_request_tgt
                        );
                      });
                    console.log("tried export_request_tgt:", export_request_tgt);
                  }
                }
              } else {
                // non tgt cut is a render
                if (do_low_res) {
                  console.log("LOW RES RENDER video_filenames:", sequence["proxy"]);
                  const cut_id = uuidv4();
                  const cut_request = {
                    save_type: "basic",
                    high_quality: "False",
                    video_filenames: sequence["proxy"],
                    audio: "silent", // todo:  do we want this to be "silent"?
                    version: null,
                    stream_path: null,
                    thumbnail: null,
                    created_at: serverTimestamp(),
                    completed_at: null,
                  };
        
                  const cutRef = doc(storyRef, "cuts", cut_id)
                  setDoc(cutRef, cut_request, {merge:true})
                    .then(() => {
                        dispatch({ type: "START_CUT", payload: {} });
                    })
                    .catch((error) => {
                      console.log("Error creating cut request:", cut_request);
                    });
                  console.log("tried_cut:", cut_request);
                }
        
                if (do_high_res) {
                  console.log("HIGH RES RENDER video_filenames:", sequence["files"]);
                  // note: handling of video_path is not addressed for tgt videos (e.g. tgt_stream_video exist in low res, not hgh)
                  //   could:  backfill low res mized with high, or propogate a link to a private high res file
        
                  const cut_request_hq = {
                    save_type: "basic",
                    high_quality: "True",
                    video_filenames: sequence["files"],
                    audio: "silent",
                    version: null,
                    stream_path: null,
                    thumbnail: null,
                    created_at: serverTimestamp(),
                    completed_at: null,
                  };
        
                  const cut_id_hq = uuidv4();
                  const cutRefHq = doc(storyRef, "cuts", cut_id_hq)
                  setDoc(cutRefHq, cut_request_hq, {merge:true})
                    .then(() => {
                        dispatch({ type: "START_CUT", payload: {} });
                        logEvent(analytics, 'SAVE_CUT')
                    })
                    .catch((error) => {
                      console.log("Error creating HQ cut request:", cut_request_hq);
                    });
                  console.log("tried_cut HQ:", cut_request_hq);
        
                  if (do_export) {
                    console.log("export video_filenames:", sequence["files"]);
                    const export_request = {
                      video_filenames: sequence["files"],
                      audio: tgt_audio, // null  ... note:  we are allowing the audio from tgt to come out in export, even if trimming media is not complete ...
                      version: null,
                      dst_zip_url: null,
                      created_at: serverTimestamp(),
                      tgt: null,
                    };
                    const export_id = cut_id_hq; // uuidv4()
                    const exportRefTgt = doc(storyRef, "exports", export_id)
                    setDoc(exportRefTgt, export_request, {merge:true})
                      .then(() => {
                        dispatch({ type: "START_EXPORT", payload: {} });
                      })
                      .catch((error) => {
                        console.log("Error creating export request:", export_request);
                      });
                    console.log("tried export_request (non_tgt):", export_request);
                  }
                }
              }
        
              dispatch({ type: "CLEAR_LAST_CUT", payload: {} });
              dispatch({ type: "CLEAR_SAVE_CUT", payload: {} });
              dispatch({ type: "CLEAR_LAST_EXPORT", payload: {} });
        }
    }, [state.app.savingCut])


    // propate last_cut to cloud.  ONLY used for clearing after a save. 
    useEffect(() => {
        const activeStoryRef = state.stories.activeStoryRef
        if(!activeStoryRef) return;
        const unsetLastCut = async () => {
            return await setDoc(activeStoryRef, {"last_cut": null}, {merge:true})
            .catch((error) => {
                console.log('Error updating last_cut:', error)
                return error
                })
        }
        if (state.app.savingCut && state.stories.activeStory.last_cut === null) {
            unsetLastCut()
        }
    }, [state.stories.activeStory.last_cut])


    // propate last_export to cloud.  used for clearing after a save. 
    useEffect(() => {
        if (state.stories.activeStoryRef) {
            const activeStoryRef = state.stories.activeStoryRef

            const setStorysLastExport = async (last_export) => {
                return await setDoc(state.stories.activeStoryRef, {"last_export": last_export}, {merge:true})
                .catch((error) => {
                    console.log('Error updating last_export:', last_export)
                    return error
                    })
            }
            if (state.stories.activeStory.last_export === null) {
                console.log('EFFECT, LAST EXPORT:', state.stories.activeStory.last_export)
                setStorysLastExport(state.stories.activeStory.last_export)
            }
        }
    }, [state.stories.activeStory.last_export])


    // STORY LIST
    // Listen for updates to story list (based on currentUser)
    useEffect(() => {
        if (!currentUser) return
        const getStories = () => {
            let unSubscribe = () => { };
            try {
                const storiesRef = collection(doc(db, 'users', currentUser.uid), 'stories')

                unSubscribe = onSnapshot(storiesRef, async querySnapshot => {
                    const storyList = [];
                    querySnapshot.forEach(async (doc) => {
                        if (doc.exists() && (!doc.get("deleted_at") || doc.get("deleted_at") == null)) {
                            const data = doc.data();
                            const story = { ref: doc.ref, ...data }
                            storyList.push(story)
                        }
                    })
                    dispatch({ type: "UPDATE_STORY_LIST", payload: { storyList } });
                });
            } catch (error) {
                console.log('Error subscribing to updates for story list: ', error);
            } finally {
                return () => unSubscribe();
            }
        }
        getStories();
    }, [dispatch, currentUser])

    // // SHARED STORY LIST
    // // Listen for updates to shared story list (based on currentUser)
    // useEffect(() => {
    //     if (!currentUser) return
    //     let unSubscribe = () => { };
    //     try {
    //         const userRef = await setDoc(doc(db, 'users').doc(currentUser.uid);
    //         const storiesRef = db.collectionGroup('stories')
    //             .where("sharedWith", "array-contains", userRef)
    //             .where("deleted_at", "==", null);
    //         unSubscribe = storiesRef.onSnapshot(querySnapshot => {
    //             const storyList = [];
    //             querySnapshot.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const data = doc.data();
    //                     const story = { ref: doc.ref, ...data }
    //                     storyList.push(story)
    //                 }
    //             })
    //             dispatch({ type: "UPDATE_SHARED_STORY_LIST", payload: { sharedStoryList: storyList } });
    //         })
    //     } catch (error) {
    //         console.log('Error subscribing to updates for shared story list: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }


    // }, [dispatch, currentUser])

    // // ALBUM LIST
    // // Listen for updates to album list (based on currentUser)
    // useEffect(() => {
    //     if (currentUser) {
    //         let unSubscribe = () => { };
    //         try {
    //             const albumsRef = await setDoc(doc(db, 'albums')
    //                 .where("status", "==", "display")
    //                 .where("deleted_at", "==", null);
    //             unSubscribe = albumsRef.onSnapshot(querySnapshot => {
    //                 const albumList = [];
    //                 querySnapshot.forEach((doc) => {
    //                     if (doc.exists) {
    //                         const data = doc.data();
    //                         const album = { ref: doc.ref, ...data }
    //                         albumList.push(album)
    //                     }
    //                 })
    //                 dispatch({ type: "UPDATE_ALBUM_LIST", payload: { albumList } });
    //             })
    //         } catch (error) {
    //             console.log('Error subscribing to updates for album list: ', error)
    //         } finally {
    //             return () => unSubscribe();
    //         }


    //     }
    // }, [dispatch, currentUser])

    // ACTIVE STORY
    // Listen for updates to activeStory (based on state.activeStoryRef)
    useEffect(() => {
        console.dir("active story", state.stories.activeStoryRef)
        console.dir("active story 1", state.stories.activeStory)
        
        if (!state.stories.activeStoryRef || !Object.keys(state.stories.activeStoryRef).length) return
        //console.dir("active ref check", (state.stories.activeStoryRef && state.stories.activeStory && (state.stories.activeStoryRef.path.segments[state.stories.activeStoryRef.path.segments.length - 1] == state.stories.activeStory.ref[state.stories.activeStory.ref.path.segments.length - 1])))
        /*let unSubscribe = () => { };
        try {*/
            const activeStoryRef = state.stories.activeStoryRef
           /* unSubscribe =
                onSnapshot(doc(activeStoryRef.path), snapDoc => {*/
            const getActiveStory = async () => {                
                    if(activeStoryRef) {
                        let unSubscribe = () => { };
                        try {
                            /*var activeStory = await getDoc(db, activeStoryRef);
                            if(activeStory) activeStory = activeStory.data();
                            else return;*/
                            console.log("get active story async")
                            unSubscribe = onSnapshot(activeStoryRef, async querySnapshot => {
                                //let activeStory = await getDoc(querySnapshot);
                                //if(!activeStory) return
                                //activeStory = activeStory.data();
                                if(!querySnapshot) return;
                                let activeStory = querySnapshot.data()
                                if(!activeStory) return;
                                activeStory.ref = activeStoryRef;
                                // for back compatibility < 0.3.21 to append an ID to each part if it does not have one
                                if(activeStory?.parts && !activeStory.parts[0].id) {
                                    for (var i = 0; i < activeStory.parts.length; i++) {
                                        if (!('id' in activeStory.parts[i])) {
                                            activeStory.parts[i] = {...  activeStory.parts[i], ... {id: nanoid()}}
                                        }
                                    }
                                }
                                // check for deleted
                                if(activeStory.deleted_at) {
                                    activeStory.isDeleted = true;
                                }
                                console.log("get active story")
                                dispatch({ type: "UPDATE_ACTIVE_STORY", payload: { "activeStory": activeStory } });
                            })
                        } catch (error) {
                            console.log('Error subscribing to updates for story list: ', error)
                        } finally {
                            return () => unSubscribe();
                        }    
                    } else {
                        dispatch({ type: "CLEAR_ACTIVE_STORY_REF" });
                    }
                //});
            }
            getActiveStory();
        /*} catch (error) {
            console.log('Error subscribing to updates for active story: ', error)
        } finally {
            return () => unSubscribe();
        }*/
    }, [state.stories.activeStoryRef, currentUser]);


    // ACTIVE STORY SETTINGS
    // Listen for updates to activeStory (based on state.activeStoryRef)
    useEffect(() => {
        if (!state.stories.activeStory.ref) return
        let unSubscribeSettings = () => { };
        try {
            const settingsRef = doc(state.stories.activeStory.ref, 'settings', '0')
            let did_dispatch = false;
            unSubscribeSettings =
                onSnapshot(settingsRef, (snapDoc => {
                    if (snapDoc.exists()) {
                        const settings = snapDoc.data();
                        dispatch({ type: "UPDATE_STORY_SETTINGS", payload: { settings } });
                        did_dispatch = true
                    } else {
                        if (currentUser) {
                            const initSettings = initialState.app.settings;
                            console.log('MAKING SETTINGS FOR THIS STORY', initSettings)
                            dispatch({ type: "UPDATE_STORY_SETTINGS", payload: { "settings": initSettings } });
                            setDoc(settingsRef, initSettings, {merge:true})
                        }
                    }
                }));

        } catch (error) {
            const msg = error.message
            console.log('Error subscribing to updates for story settings: ', msg)
            if (msg.search("empty")> 1 ){
                console.log('EMPTY DATA for story settings: ', msg)
            }
            else {
                console.log('ANOTHER ERROR for story settings: ', error)
            }
        } finally {
            return () => unSubscribeSettings();
        }
    }, [state.stories.activeStory.ref]);

    // SETTINGS
    // Update story settings
    useEffect(() => {
        if(!state.stories.activeStoryRef) return
        const updateSettings = async () => {
            try {
                const settingsRef = doc(state.stories.activeStoryRef, 'settings', '0')
                await setDoc(settingsRef, state.app.settings, {merge:true})            
            } catch (error) {
                const msg = error.message
                console.log('Error updating story settings: ', msg)
            }
        }
        updateSettings();
        
    }, [state.app.settings]);

    // PORTAL
    // Listen for requests to make a new portal
    useEffect(() => {

            if (!currentUser || !state.stories.activeStoryRef){
                return               
            } 
            if (!state.app.portal.requested_id){
                console.log("no portal requested ID")
                return
            } 

            if (state.stories.activeStory.portal_id){
                console.log("WARNING:   Portal already exists, don't make another", state.stories.activeStory.portal_id)
                return

                //ToDO later: enable user request for portal URL
                // Q: If there is an existing portal for this story, should we delete it?
                //
            } 

            const portal_id = state.app.portal.requested_id
            const createPortal = async (portal_id) => {
                const portalRef = doc(db, 'portals', portal_id);
                const newPortal = {creator_id: currentUser.uid, 
                    story_id: state.stories.activeStoryRef.id,
                    created_at: serverTimestamp(), 
                    deleted_at: null
                }

                return await function() {
                    const ref = setDoc(portalRef, newPortal, {merge:true});
                    return ref;
                } .catch((error) => {
                    console.log('Error creating new portal:', portal_id)
                    return error
                })
            }

            const setStorysPortal = async (portal_id) => {
                return await function() { 
                    const story = setDoc(state.stories.activeStoryRef, {"portal_id": portal_id}, {merge:true});
                    console.log("Story updated successfully.");
                    return story;
                }
                .catch((error) => {
                    console.log('Error updating portal id:', portal_id)
                    return error
                    })
            }

            // ASIDE:  it would be nice if these two actions were atomic
            // maybe serverside logic would help
            createPortal(portal_id)
            setStorysPortal(portal_id)
            console.log("CREATED PORTAL", state.app.portal.requested_id)
            dispatch({ type: "CLEAR_REQUESTED_PORTAL" });


            //await createPortal(portal_id)

    }, [state.app.portal.requested_id]);

    // Listen for updates to STORY (based on change in PORTAL ID)
    useEffect(() => {
        // const portal = state.app.portal
        // if (!portal.id) {
        //     // A null id will get initialized to the portal id in the URL
        //     let portal_id = "non-portal"
        //     if (loc.pathname.match('/p/*')){
        //         portal_id = (loc.pathname.match('/p/*')[0] === '/p/') ? loc.pathname.substring(3) : "non-portal"        
        //         dispatch({ type: "UPDATE_PORTAL", payload: { portal_id } });
        //     } else {
        //         console.log("NONMATCH PORTAL")
        //     }
        // }
        // if (portal.id) {
        //     console.log("EFFECT PORTAL_ID", portal.id)
        //     // this logic could move to initializeActiveStoryRef

        //     const portalRef =  await setDoc(doc(db, 'portals').doc(portal.id) 
        //     const _portal = portalRef.get().then((doc) => {
        //         if (doc.exists) {
        //             console.log("PORTAL data:", doc.data());
        //             const user_id = doc.data().creator_id
        //             const story_id = doc.data().story_id

        //             const activeStoryRefX =  await setDoc(doc(db, 'users').doc(user_id).collection('stories').doc(story_id) // db.database().ref('portal/' + portal_id + '/story_path');
        //             dispatch({ type: "UPDATE_ACTIVE_STORY_REF", payload: { activeStoryRefX } });

        //             let unSubscribe = () => { };
        //             //try {     
        //                     unSubscribe =
        //                     activeStoryRefX.onSnapshot(doc => {
        //                         if (doc.exists && !doc.get("deleted_at")) {
        //                             const activeStory = doc.data();
        //                             activeStory.ref = doc.ref;
        //                             dispatch({ type: "UPDATE_ACTIVE_STORY", payload: { activeStory } });
        //                         } else {
        //                             console.log("Story? No such document!");
        //                             //dispatch({ type: "CLEAR_ACTIVE_STORY_REF" });
        //                         }
        //                     });
        //             // catch (error) {
        //             //         console.log('Error querying data for portal defined Story: ', error)
        //             //     } finally {
        //             //         return () => unSubscribe();
        //             //     }
        //             // }

        //         } else {
        //             // doc.data() will be undefined in this case
        //             console.log("No such document!");
        //         }
        //     }).catch((error) => {
        //         console.log("Error getting document:", error);
        //     });
        // }
    }, [dispatch, state.app.portal])


    // LAST ACTIVE STORY
    // Set lastActiveStory for the user
    useEffect(() => {
        if (!currentUser || !state.stories.activeStory || !state.stories.activeStory.ref) return
        const userRef = doc(db, 'users', currentUser.uid);
        const setLast = async () => {
            await setDoc(userRef, { "lastActiveStory": state.stories.activeStory.ref.path }, {merge:true}).catch((error) => {
                console.log('Error setting lastActiveStory for user: ', userRef.id, error)
            })
        }
        setLast();
    }, [dispatch, state.stories.activeStory, currentUser]);

   
    // useEffect(() => {
    //     if (!currentUser || !state.stories.activeStory || !state.stories.activeStory.ref) {
    //         dispatch({ type: "SET_USER_IS_STORY_OWNER", payload: { userIsStoryOwner: false } });
    //         return;
    //     }
        
    //     let userIsStoryOwner = false;
    //     const storyPathArray = state.stories.activeStory.ref.path.split("/");
    //     const userIndex = storyPathArray.findIndex(item => item === "users") + 1;
    //     const ownerIdInPath = storyPathArray[userIndex];
    
    //     console.log("Current User ID:", currentUser.uid);
    //     console.log("Owner ID in Path:", ownerIdInPath);
    
    //     if (currentUser.uid === ownerIdInPath) {
    //         userIsStoryOwner = true;
    //     }
    
    //     console.log("Final Calculated userIsStoryOwner:", userIsStoryOwner);
    //     dispatch({ type: "SET_USER_IS_STORY_OWNER", payload: { userIsStoryOwner } });
    // }, [currentUser, state.stories.activeStoryRef, state.stories.activeStory]);
    
    
    useEffect(() => {
        if (currentUser && state.stories.activeStory && state.stories.activeStory.ref) {
            let userIsStoryOwner = false;
            
            const storyPathArray = state.stories.activeStory.ref.path.split("/");
            const userIndex = storyPathArray.findIndex((item) => item === "users") + 1;
    
            if (storyPathArray[userIndex] && currentUser.uid === storyPathArray[userIndex]) {
                if(!state.app.userIsStoryOwner) dispatch({ type: "SET_USER_IS_STORY_OWNER", payload: { userIsStoryOwner: true } });
            } else {
                if(state.app.userIsStoryOwner) dispatch({ type: "SET_USER_IS_STORY_OWNER", payload: { userIsStoryOwner: false } });
            }
        } else {
            if(!state.app.userIsStoryOwner) return;
            dispatch({ type: "SET_USER_IS_STORY_OWNER", payload: { userIsStoryOwner: false } });
        }
    }, [currentUser, state.stories.activeStory]);
    


    // // ACTIVE CARD LIST is similar to ACTIVE SHOT LIST ... see below
    // // Listen for changes to activeShotList
    // useEffect(() => {
    //     if (!currentUser || !state.stories.activeStoryRef) return
    //     let unSubscribe = () => { };
    //     try {
    //         const activeStoryRef = state.stories.activeStoryRef
    //         const shotsRef = activeStoryRef
    //             .collection('cards')
    //             .where('deleted_at', '==', null)
    //             .where('display', '==', true)
    //             .orderBy('orientation', 'desc')
    //             .orderBy('created_at', 'asc')
    //         unSubscribe = shotsRef.onSnapshot(querySnapshot => {
    //             const activeShotList = [];
    //             const sourceSet = [];
    //             querySnapshot.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const card = doc.data();
    //                     card.ref = doc.ref;
    //                     activeShotList.push(card)
    //                     if (card.image_source_id) {
    //                         sourceSet.push(card.image_source_id)
    //                     }
    //                 }
    //             })
    //             dispatch({ type: "UPDATE_ACTIVE_SHOT_LIST", payload: { activeShotList } });
    //             dispatch({ type: "UPDATE_ALBUM_SOURCE_SET", payload: { sourceSet } });
    //             dispatch({ type: "APP_LOADED", payload: {} });
    //         })
    //     }
    //     catch (error) {
    //         console.log('Error querying data for activeShotList: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }
    // }, [dispatch, state.stories.activeStoryRef, currentUser]);

    // ACTIVE SHOT LIST
    
    useEffect(() => {
        // Listen for changes to activeShotList
         //if (!currentUser || !state.stories.activeStoryRef) { console.log("return"); return }
        /*if (!state.stories.activeStoryRef) {
            initializeFromPortal("non-portal")
            /*console.log(userRef)
            console.log(storyRef)
            console.log(portal)
            console.log("init")
            if(!userRef) userRef = null
            if(!storyRef) storyRef = null
            if(!portal) portal = null
            initializeActiveStoryRef(userRef, storyRef, portal)
        }*/
        //if (!state.stories.activeStory) return
            const getShots = () => {
                let unSubscribe = () => { };
                try {
                    const activeStoryRef = state.stories.activeStoryRef
                    if(!activeStoryRef) return
                    const shotRef = query(collection(activeStoryRef, 'shots'), where('deleted_at', '==', null))
                    unSubscribe = onSnapshot(shotRef, querySnapshot => {
                        const activeShotList = [];
                        querySnapshot.forEach((doc) => {
                            if (doc.exists()) {
                                var shot = doc.data();
                                shot.ref = doc.ref;
                                activeShotList.push(shot)
                            }
                        })

                        if (activeShotList.length == 1) {
                            // if a single shot is in this list, we append
                            // this supports a rapid local render
                            const longer_list = state.stories.activeShotList.concat(activeShotList)
            
                            // in case we keep getting a single value over and over, it should not grow
                                const unique_list = longer_list.filter((v, i, a) => a.indexOf(v) === i)
                                
                                dispatch({ type: "UPDATE_ACTIVE_SHOT_LIST", payload: { activeShotList:  unique_list} });
                        } else {
                            // this
                            dispatch({ type: "UPDATE_ACTIVE_SHOT_LIST", payload: { activeShotList } });                    
                        }
                        dispatch({ type: "APP_LOADED", payload: {} });
                        // dispatch({ type: "UPDATE_ALBUM_SOURCE_SET", payload: { sourceSet } });
                    });
                }
                catch (error) {
                    console.log('Error querying data for activeShotList: ', error)
                } finally {
                    return () => { unSubscribe(); }
                }
            }
            getShots();

            const getTakes = () => {
                if (!state.stories.activeStory || !state.stories.activeStoryRef) return
                let unSubscribe = () => { };
                try {
                    //console.log("try")
                    const activeStoryRef = state.stories.activeStoryRef
                    const storyRef = query(collection(activeStoryRef, 'takes'), where('deleted_at', '==', null))
                    unSubscribe = onSnapshot(storyRef, querySnapshot => {
                        const activeTakeList = [];
                        querySnapshot.forEach(snapDoc => {
                            if (snapDoc.exists()) {
                                var take = snapDoc.data();
                                take.ref = snapDoc.ref;
                                activeTakeList.push(take)
                            }
                        })
                        dispatch({ type: "UPDATE_ACTIVE_TAKE_LIST", payload: { activeTakeList } });
                    })
                }
                catch (error) {
                    console.log('Error querying data for activeTakeList: ', error)
                } finally {
                    return () => unSubscribe();
                }
            }
            getTakes();

    }, [dispatch, state.stories.activeStory]);



    
    // // NUM SHOTS  -  just use shots.length 
    // // Listen for changes to activeStory
    // useEffect(() => {
    //     if (!state.stories.activeStory) return
    //     if (!state.stories.activeShotList) return

    //     // // it seems we should count up the length of the lists in ORDER.   but the fields dont exist yet!  maybe activeStory is a promose?
    //     // if ("parts" in state.stories.activeStory && "order" in state.stories.activeStory.parts) {
    //     //     const num_shots = state.stories.activeStory.parts.reduce((count, part) => count = count + part.order.length, 0) 
    //     // } else {
    //     //     console.log("WARNING:  shot count is FAILING!", state.stories.activeStory)
    //     // }

    //     const num_shots = state.stories.activeShotList.length   // instead we do this, which SHOULD work beacuse deleted_at is filetered out
    //     dispatch({ type: "UPDATE_NUM_SHOTS", payload: { num_shots: num_shots } });              
        

    // }, [dispatch, state.stories.activeShotList]);  //  state.stories.activeStory, 


    // ACTIVE TAKE LIST
    // Listen for changes to activeTakeList
    /* useEffect(() => {
        //if (!currentUser || !state.stories.activeStoryRef) return  // user dependency removed.
        if (!state.stories.activeStory) return
        if (!state.stories.activeStoryRef) return
        let unSubscribe = () => { };
        try {
            //console.log("try")
            const activeStoryRef = state.stories.activeStoryRef
            const storyRef = query(collection(activeStoryRef, 'takes'), where('deleted_at', '==', null))
            unSubscribe = onSnapshot(storyRef, querySnapshot => {
                const activeTakeList = [];
                querySnapshot.forEach(snapDoc => {

                    if (snapDoc.exists()) {
                        var take = snapDoc.data();
                        take.ref = snapDoc.ref;
                        activeTakeList.push(take)
                    }
                })
                dispatch({ type: "UPDATE_ACTIVE_TAKE_LIST", payload: { activeTakeList } });
            })
        }
        catch (error) {
            console.log('Error querying data for activeTakeList: ', error)
        } finally {
            return () => unSubscribe();
        }
    }, [state.stories.activeStoryRef]); */

    // ACTIVE VENEER LIST
    // Listen for changes to activeVeneerList, and update the LUT
    useEffect(() => {
        if (!state.stories.activeStory) return
        if (!state.stories.activeStory.ref) return
        let unSubscribe = () => { };
        try {
            const activeStoryRef = state.stories.activeStory.ref
            const veneerRef = collection(activeStoryRef, 'veneer')
                // .where('deleted_at', '==', null)

            unSubscribe = onSnapshot(veneerRef, querySnapshot => {
                const activeVeneerList = [];
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        var vn = doc.data();
                        vn.ref = doc.ref;
                        activeVeneerList.push(vn)
                    }
                })
                dispatch({ type: "UPDATE_ACTIVE_VENEER_LUT", payload: { activeVeneerList } });
            })
        }
        catch (error) {
            console.log('Error querying data for activeVeneerList: ', error)
        } finally {
            return () => unSubscribe(); 
        }
    }, [state.stories.activeStory.ref]);


  useEffect(() => {
        let prioritizeLivePreview = true
        if (!state.app.userIsStoryOwner) {
          prioritizeLivePreview = !state.app.settings.communityCanViewSavedPreview
        }
        dispatch({ type: "UPDATE_PRIORITIZE_LIVE_PREVIEW", payload: { prioritizeLivePreview:  prioritizeLivePreview} });
    }, [state.app.userIsStoryOwner, state.app.settings.communityCanViewSavedPreview]);

    useEffect(() => {
        if(!state.stories.generateStory) return
        const story = emptyStory(state.stories.generateStory.portal_id, false);
        dispatch({ type:"NEW_ACTIVE_STORY", payload: { newStory:story }});

    }, [state.stories.generateStory]);


    // // ACTIVE ALBUM
    // // Fetch data for activeAlbum (based on state.activeAlbumRef)
    // useEffect(() => {

    //     const setupDefaultAlbum = async () => {
    //         const albumRef = await setDoc(doc(db, 'albums').doc();
    //         const newAlbum = albumConstructor();
    //         return albumRef.set(newAlbum)
    //             .then(() => albumRef)
    //             .catch((error) => {
    //                 console.log('Error creating default album:')
    //                 return error
    //             })
    //     }

    //     const initializeActiveAlbumRef = async () => {
    //         let activeAlbumRef = ""
    //         if (state.inspiration.initialAlbumID) {
    //             activeAlbumRef = await setDoc(doc(db, 'albums').doc(state.inspiration.initialAlbumID);
    //             // Check if album exists 
    //             activeAlbumRef = await activeAlbumRef.get().then((doc) => {
    //                 if (doc.exists && !doc.get("deleted_at")) {
    //                     return activeAlbumRef
    //                 } else {
    //                     return null;
    //                 }
    //             })
    //         }

    //         // If the album does not exist, check if there are other albums to set it to and set it to the first one
    //         if (!activeAlbumRef) {
    //             // The original activeAlbum no longer exists, so check fo any other albums
    //             activeAlbumRef = await await setDoc(doc(db, 'albums')
    //                 .where("status", "==", "display")
    //                 .where("deleted_at", "==", null)
    //                 .limit(1)
    //                 .get().then((querySnapshot) => {
    //                     if (!querySnapshot.empty) {
    //                         return querySnapshot.docs[0].ref
    //                     } else {
    //                         return null
    //                     }
    //                 })
    //         }

    //         // If still no albums, create one and set it
    //         if (!activeAlbumRef) {
    //             activeAlbumRef = await setupDefaultAlbum()
    //                 .then((newAlbumRef) => newAlbumRef)
    //                 .catch((error) => {
    //                     console.log('Unable to initialize activeAlbumRef. Creating new album failed.', error)
    //                 })
    //         }

    //         if (activeAlbumRef) {
    //             dispatch({ type: "UPDATE_ACTIVE_ALBUM_REF", payload: { activeAlbumRef } });
    //         }

    //     }


    //     if (!currentUser) return
    //     if (!state.inspiration.activeAlbumRef) {
    //         try {
    //             initializeActiveAlbumRef();
    //         } catch (error) {
    //             console.log('Error initializing active album: ', error)
    //         }
    //         return
    //     }

    //     let unSubscribe = () => { };
    //     try {
    //         const activeAlbumRef = state.inspiration.activeAlbumRef
    //         unSubscribe = activeAlbumRef.onSnapshot(doc => {
    //             if (doc.exists) {
    //                 const activeAlbum = doc.data();
    //                 if (!activeAlbum.deleted_at) {
    //                     activeAlbum.ref = doc.ref;
    //                     dispatch({ type: "UPDATE_ACTIVE_ALBUM", payload: { activeAlbum } });
    //                 }
    //             }
    //         });
    //     } catch (error) {
    //         console.log('Error subscribing to active album updates: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }
    // }, [dispatch, state.inspiration.activeAlbumRef, state.inspiration.initialAlbumID, currentUser]);

    // // ACTIVE ALBUM SHOT LIST
    // // Listen for changes to activeAlbumCardList
    // useEffect(() => {
    //     if (!currentUser || !state.inspiration.activeAlbumRef) return
    //     const activeAlbumRef = state.inspiration.activeAlbumRef
    //     // const activeAlbumRef = await setDoc(doc(db, 'albums').doc(state.inspiration.activeAlbumRef.id)
    //     // if (typeof activeAlbumRef.collection !== 'function') return
    //     let unSubscribe = () => { };
    //     try {
    //         const shotsRef = activeAlbumRef
    //             .collection('cards')
    //             .where('deleted_at', '==', null)
    //             .where('display', '==', true)
    //             .orderBy('orientation', 'desc')
    //             .orderBy('created_at', 'asc')
    //         unSubscribe = shotsRef.onSnapshot(querySnapshot => {
    //             const cardList = [];
    //             querySnapshot.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const card = doc.data();
    //                     card.ref = doc.ref;
    //                     cardList.push(card)
    //                 }
    //             })
    //             dispatch({ type: "UPDATE_ACTIVE_ALBUM_SHOT_LIST", payload: { activeAlbumCardList: cardList } });
    //         })
    //     }
    //     catch (error) {
    //         console.log('Error querying data for activeAlbumCardList: ', error)
    //     } finally {
    //         return () => unSubscribe();
    //     }
    // }, [currentUser, dispatch, state.inspiration.activeAlbumRef]);

    // TODO: preload images for story and inspiration? 
    
    
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            { children}
        </AppContext.Provider>
    );
}

export default AppContext;