import React from 'react'
import { Radio, FormControlLabel } from '@mui/material'
import theme from '../styles/theme'

//const RadioViewToggle = () => 

function ViewToggle(props) {

	/*function handleChange(viewLatestTake) {
		return props.setViewLatestTake(!viewLatestTake)
		console.log("viewLatestTake", viewLatestTake)
	}*/

	return ( <FormControlLabel sx={{ margin:'0 0 1em', 'span': { margin:0 }, 'color': theme.palette.primary.contrastText }} toggle 
		control={<Radio sx={{ color:theme.palette.primary.contrastText, opacity:'100%', '&.Mui-checked': { color:theme.palette.primary.contrastText }, '.MuiSvgIcon-root:nth-child(2)': { position:'absolute', top:0 } }} />}
		label={props.label ? props.label : "view last take"}
		value={props.value}
		selected={props.value}
		//onChange={ (viewLatestTake) => handleChange(props.viewLatestTake) }
		/>
		)
}


export default ViewToggle
