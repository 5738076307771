import { createTheme } from '@mui/material/styles';

//  TODO:   remove this file

const theme = createTheme({
    palette: {
        primary: {
            light: '#8F8F8F',
            main: '#272727',
            dark: '#272727',
            darkest: '#1b1c1d', 
            contrastText: '#ffffff',
        },
        secondary: {
            light: 'rgba(217, 217, 217, 0.25)',
            main: 'rgb(180,180,180)',
            dark: '#272727',
            contrastText: '#f8f8f8',
        },
        tertiary: {
            light: '#D9D9D9',
            main: '#272727',
            dark: '#272727',
            darkest: '#1b1c1d', 
            contrastText: '#75757575',
        },
        danger: {
            light: '#8b3bc1',
            main: '#B20B0B',
            dark: '#4d077c',
            contrastText: '#fff',
        },


        // secondary: {
        //     light: '#ffff5a',
        //     main: '#fffb04',
        //     dark: '#c8c800',
        //     contrastText: '#000',
        // },
        // warning: {
        //     main: '#7908AA',
        //     dark: '#5E0684',
        //     contrastText: '#fff',
        // },
        // TODO: insert palette colors here
        // error: {
        //   light: red[50],
        //   main: red[500],
        //   dark: red[700],
        // },
        // success: {
        //   light: green[50],
        //   main: green[500],
        //   dark: green[700],
        // },
    },
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Helvetica', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeightRegular: 500, // Roboto
        fontWeightMedium: 700, // Roboto Condensed
        color:"#272727", 
        brand: {
            fontFamily: ["Lato", "Helvetica Neue", "Arial", "Helvetica", "sans-serif"].join(','), 
            fontSize: "26px", 
            lineHeight: "32px", 
            fontWeight: "700", 
            color:"#272727", 
            display:"block"
        }, 
        h1: {
            fontSize: "22px", 
            lineHeight: "32px", 
            fontWeight: "400", 
            color:"#272727", 
        }, 
        h2: {
            fontSize: "22px", 
            lineHeight: "32px", 
            fontWeight: "500", 
            textTransform: "uppercase", 
            color:"#272727"
        }, 
        h3: {
            fontSize: "18px", 
            lineHeight: "32px", 
            fontWeight: "400", 
            marginBottom: "15px",
            color:"#272727", 
            '@media (max-width:600px)': {
                fontSize: '14px',
                lineHeight:"20px"
              },
        },
        h4: {
            fontSize: "14px", 
            lineHeight: "20px", 
            fontWeight: "500", 
            color:"#272727"
        }, 
        h5: {
            fontSize: "12px", 
            lineHeight: "20px", 
            fontWeight: "500", 
            color:"#272727"
        }, 
        body1: {
            fontSize: "14px",
            lineHeight: "20px", 
            color:"#757575"
        }, 
        body2: {
            fontSize: "16px",
            lineHeight: "20px", 
            fontWeight: 400, 
            color:"#272727"
        }, 
        byline: {
            fontSize: "14px",
            lineHeight: "18px", 
            fontWeight: 700, 
            color:"#272727"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius:0,
                    boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.15)", 
                    ...(ownerState.variant === 'contained' && {
                        backgroundColor:"#272727", 
                        color:"#fff", 
                        '&:hover': {
                            backgroundColor:"#272727"
                        }
                    }),                    
                    ...(ownerState.variant === 'contained' && 
                    ownerState.color === 'secondary' && {
                        backgroundColor:"rgb(180,180,180)", 
                        color:"#272727", 
                        '&:hover': {
                            backgroundColor:"rgb(180,180,180)"
                        }
                    }),
                    ...(ownerState.variant === 'outlined' && {
                        '&:hover': {
                            backgroundColor:"transparent"
                        }
                    }), 
                    ...(ownerState.variant === 'shadowed' && {
                        marginLeft:'25px', 
                        '&:hover': { 
                            background:theme.palette.secondary.light 
                        }, 
                        [theme.breakpoints.down('sm')]: { 
                            marginLeft:'0', marginBottom:'1em', width:'100%' 
                        } 
                    })
                }) 
            }
        }, 
        MuiLink: {
            styleOverrides: {
                root: {
                    '&:hover' : {
                        textDecoration:"none"
                    }
                }
            }
        }, 
        MuiContainer: {
            styleOverrides: {
                root: {
                    margin:0, 
                    border:0,
                    color:"#272727", 
                    maxWidth:'100%'
                }
            }
        }, 
        MuiGrid2: {
            styleOverrides: {
                root: {
                    alignItems:'stretch',
                    '> .MuiGrid2-root > .MuiGrid2-root': {
                        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.15)', 
                        padding:0, 
                        height:'100%'
                    }, 
                    '> .MuiGrid2-root > .add-item': {
                        padding:'1em', 
                        minHeight:'200px'
                    }
                }
            } 
        }
    }
});

// const fontHeader = {
//   color: rawTheme.palette.text.primary,
//   fontWeight: rawTheme.typography.fontWeightMedium,
//   fontFamily: "'Roboto Condensed', sans-serif",
//   textTransform: 'uppercase',
// };



// const theme = {
//   ...rawTheme,
//   /* override browser default */
//   palette: {
//     ...rawTheme.palette,
//     background: {
//       ...rawTheme.palette.background,
//       default: rawTheme.palette.common.white,
//       placeholder: grey[200],
//     },
//   },
//   typography: {
//     ...rawTheme.typography,
//     fontHeader,
//     h1: {
//       ...rawTheme.typography.h1,
//       ...fontHeader,
//       letterSpacing: 0,
//       fontSize: 60,
//     },
//     h2: {
//       ...rawTheme.typography.h2,
//       ...fontHeader,
//       fontSize: 48,
//     },
//     h3: {
//       ...rawTheme.typography.h3,
//       ...fontHeader,
//       fontSize: 42,
//     },
//     h4: {
//       ...rawTheme.typography.h4,
//       ...fontHeader,
//       fontSize: 36,
//     },
//     h5: {
//       ...rawTheme.typography.h5,
//       fontSize: 20,
//       fontWeight: rawTheme.typography.fontWeightLight,
//     },
//     h6: {
//       ...rawTheme.typography.h6,
//       ...fontHeader,
//       fontSize: 18,
//     },
//     subtitle1: {
//       ...rawTheme.typography.subtitle1,
//       fontSize: 18,
//     },
//     body1: {
//       ...rawTheme.typography.body2,
//       fontWeight: rawTheme.typography.fontWeightRegular,
//       fontSize: 16,
//     },
//     body2: {
//       ...rawTheme.typography.body1,
//       fontSize: 14,
//     },
//   },
// };

export default theme;
