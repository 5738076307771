import React, { useContext, useEffect, useState } from "react";
import AppContext from "../utils/app-context";
import StoryPartForm from "../components/story-part-form";
import { useHistory } from "react-router-dom";
import firebase, {analytics} from "../utils/firebase.js";
import { logEvent } from "@firebase/analytics";
import ConfirmReplacementDialog from "../components/confirm-replacement-dialog"
import { Typography, Box, Container } from "@mui/material";


function RoughParts() {

    const [showSuggestionButton, setShowSuggestionButton] = React.useState(false)
    const { state, dispatch } = useContext(AppContext);

    const story = state.stories.activeStory //state.story //state.stories.activeStory

    const part1_init = state.story.parts[0].title
    const part2_init = state.story.parts[1].title
    const part3_init = state.story.parts[2].title

    const history = useHistory();
    const [part1, setPart1] = useState(part1_init);
    const [part2, setPart2] = useState(part2_init);
    const [part3, setPart3] = useState(part3_init);


    const handleContinue = () => {
        logEvent(analytics, 'BUTTON_CONTINUE_AFTER_PARTS');
        const parts = [{title:part1, order: story.parts[0].order}, 
                       {title:part2, order: story.parts[1].order},
                       {title:part3, order: story.parts[2].order}]
        dispatch({ type: "UPDATE_STORY_PARTS", payload: parts })
        history.push('/p/' + portal_id);
    }

    const CancelNewStoryAndContinue = () => {
        history.push('/p/' + portal_id);
    }


    function runTest() {
      alert("would test") 

    }

    function renderPartText() {
      alert(`Part1 is ${part1}, Part2 is ${part2}, Part3 is ${part3}`)
      setPart1("a")
      setPart2("b")
      setPart3("c")
    }

    function SuggestionButton() {  
      return (     
      <ConfirmReplacementDialog name="Get Suggestion" 
          title="Text replacement" 
          desc="Replace current text with a randomized story idea?"
          doIfConfirmed={renderPartText} />)
    }

    return( 
      <Container className="app_container">

        <Box clearing vertical="true">
          <Typography variant='h5'>
            <i  className="cancel icon" onClick={CancelNewStoryAndContinue}></i>
          </Typography>
          <Typography variant='h2'>
            Create a story
          </Typography>
        </Box>

        <Box className="suggestion">
          <Typography variant="h3">Name your parts</Typography>
        </Box>

        <StoryPartForm addTask={renderPartText} init={part1} text={part1} set={setPart1}/>
        <StoryPartForm addTask={renderPartText} init={part2} text={part2} set={setPart2}/>
        <StoryPartForm addTask={renderPartText} init={part3} text={part3} set={setPart3}/>

        <div className="action_advance">
          { showSuggestionButton ? <SuggestionButton /> : null }
          <button type="button" className="btn__primary" onClick={handleContinue}> Create </button>
        </div>
        
      </Container>
    )
}

export default RoughParts