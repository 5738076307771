import { 
    Link, 
    Typography, 
    Container, 
    Box, 
    Button, 
  } from '@mui/material';
  import React, { useContext, useEffect, useState } from "react";
  import { Helmet } from 'react-helmet';
  import AppContext from "../utils/app-context";
  import { AuthContext } from "../utils/auth-provider";
  import theme from '../styles/theme'
  import Headbar from "./Headbar";
  import Footbar from "./Footbar";
  import { products, checkout } from "../utils/stripe";
  
  function Account() {
    const { state, dispatch } = useContext(AppContext);
    const { currentUser } = useContext(AuthContext);
    const [productList, setProductList] = useState([]);
    const [plan, setPlan] = useState([]);
    const currPlan = state.userData.account;
    
    const wrapStyles = {
      padding: "3em",
      display:"flex", 
      justifyContent:"space-around", 
      maxWidth:"600px", 
      margin:"0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "1.5em",
      },
    };

    //if(!productList.length) {
    useEffect(() => {
      products('recurring').then(items => {
        console.dir("items", items)
        items.unshift({
          id: "free", 
          name: "Free", 
          description: "A free plan for exploring 10Zebra.", 
          prices: "123"
        })
        console.dir("items2", items)
        //if(items.length) setProductList(items.filter(item => item.id == "free" || (item.prices.length && item.prices[0].type == "recurring")));
        setProductList(items);
        console.dir(productList.length)
        console.dir("inner list", productList)
      });
    }, [])

    useEffect(() => {  
      const sub = productList.filter(item => item.id == plan)[0]
      console.dir(sub)
      if(sub) selectPlan(sub)
    }, [plan])
    
    console.dir("list", productList)
    return (
      <Container style={{ maxWidth:'100%', paddingLeft:0, paddingRight:0 }}>
        <Headbar title="Account" settings={true} admin={true} />
        <Box sx={wrapStyles}>
          <Box component="img" src={ state.userData.photoURL } width="80px" height="80px" />
          <Box>
            <Typography variant="h2">{ state.userData?.name }</Typography>
            <Typography variant="h2">{ state.userData?.email }</Typography>
          </Box>
        </Box>
      </Container>
    );
  }
  
  export default Account;