

const isProd = process.env.REACT_APP_FIREBASE_ENV == 'prod'

const okvAssets = {
  "banner.jpg":
    "https://okapivideo.com/wp-content/uploads/2020/11/dance_rainbow.jpg",
  "fb_logo.png": "https://rnbw.art/goog_photos_icon.png",
  "goog_logo.png": "https://rnbw.art/goog_logo.png",
  "logo_vert.png": "https://rnbw.art/ok_logo512.png",
  "processing_thumb": "https://rnbw.art/pup.png",
  "empty_thumbnail": "/assets/empty_thumbnail.svg",
};

const v0zAssets = {
  "banner.jpg": "https://okapivideo.com/wp-content/uploads/2020/11/dance_rainbow.jpg",
  "fb_logo.png": "https://10zebra.app/goog_photos_icon.png",
  "goog_logo.png": "https://10zebra.app/goog_logo.png",
  "logo_vert.png": "https://10zebra.app/z_icon_512_alphaBorder.png",
  "processing_thumb": "https://10zebra.app/walking.gif",
  "empty_thumbnail": "/assets/empty_thumbnail.svg",
  "processing_veneer_thumb_gif": isProd ? "https://storage.googleapis.com/v0zebra.appspot.com/p/asset/waiting_zebra.gif" : "https://storage.googleapis.com/v0zebra-dev.appspot.com/p/asset/waiting_zebra.gif",  // B&W gif
  "processing_veneer_thumb_jpg": isProd ? "https://storage.googleapis.com/v0zebra.appspot.com/p/asset/waiting_zebra.jpg" : "https://storage.googleapis.com/v0zebra-dev.appspot.com/p/asset/waiting_zebra.jpg",  // B&W static image ()
  "processing_veneer_streampath": isProd ? "https://storage.googleapis.com/v0zebra.appspot.com/p/asset/waiting_zebra.mp4" : "https://storage.googleapis.com/v0zebra-dev.appspot.com/p/asset/waiting_zebra.mp4",
  "processing_veneer_thumb_path": "p/asset/waiting_zebra.mp4" // relevant: used as a signal to abort transcode early in veneers
};

const rnbwAssets = {
  "banner.jpg":
    "https://okapivideo.com/wp-content/uploads/2020/11/dance_rainbow.jpg",
  "fb_logo.png": "https://rnbw.art/goog_photos_icon.png",
  "goog_logo.png": "https://rnbw.art/goog_logo.png",
  "logo_vert.png": "https://rnbw.art/rnbw_logo192.png",
  "processing_thumb": "https://rnbw.art/pup.png",
  "empty_thumbnail": "/assets/empty_thumbnail.svg",
};

let assets = {};

switch (process.env.REACT_APP_FLAVOR) {
  case "okv":
    assets = okvAssets;
    break;
  case "10z":
    assets = v0zAssets;
    break;
  case "rnbw":
    assets = rnbwAssets;
    break;
  default:
    assets = v0zAssets;
}

export default assets;
