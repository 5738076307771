

function getOrderedShots(story, shots) {
      // COMPUTE THE SEQUENCE OF SHOTS
      const shot_id_seq_seq = story.parts.map((part, key) => (part.order.map(shot_id => {return shot_id})));  
      const shot_id_seq = Array.prototype.concat.apply([], shot_id_seq_seq); // flatten 
      const shot_id_seq_defined = shot_id_seq.filter(id => typeof id !== 'undefined') // remove undefined ids
      // console.log('shot_id_seq_defined:', shot_id_seq_defined);
      const ordered_shots = shot_id_seq_defined.map(shot_id  => shots.filter(shot => shot.id===shot_id)[0]); 
      // console.log('ordered_shots:', ordered_shots);
      const ordered_shots_defined = ordered_shots.filter(item => typeof item !== 'undefined') // remove undefined items
      return ordered_shots_defined
}

function getOrderedTakeIDs(story, shots) {
      // COMPUTE THE SEQUENCE OF TAKES, prioritizing selected over latest, and removing undefined takes
      const take_id_seq_seq = story.parts.map((part, key) => (part.order.map(shot_id => {
        const this_shot = shots.filter(shot => shot.id===shot_id)[0]
        // console.log('this_shot id:', shot_id);
        // console.log('this_shot:', this_shot);
        if (typeof this_shot !== 'undefined' && this_shot) {
          //console.dir(this_shot)
          if ('selected_take_id' in this_shot && this_shot.selected_take_id && (this_shot.selected_take_id !== null)) {
            return this_shot.selected_take_id
          } else {
            return this_shot.last_take_id
          }
        }
      })));  
      const take_id_seq = Array.prototype.concat.apply([], take_id_seq_seq); // flatten 
      // console.log('take_id_seq_seq:', take_id_seq_seq);
      const take_id_seq_defined = take_id_seq.filter(take_id => (typeof take_id !== 'undefined') && (take_id !== null))  // remove undefined ids, which lack 'last_take_id'
      // console.log('take_id_seq_defined:', take_id_seq_defined);
      return take_id_seq_defined
}

export function computePercentFilmed(story, shots, takes) {
      if (story.ref && shots.length > 0 && takes.length > 0) {
          const take_id_seq_defined = getOrderedTakeIDs(story, shots)
          const ordered_shots_defined = getOrderedShots(story, shots)

          const count = take_id_seq_defined.length
          const total = ordered_shots_defined.length

          return {'count': count, 'total': total, 'frac': count/total , 'pct_str': parseFloat(100 * count / total).toFixed(0) }
        }
      else {
        return {}
      }
    }

export function computeSequence(story, shots, takes) {
      if (story.ref && shots.length > 0 && takes.length > 0) {

          const take_id_seq_defined = getOrderedTakeIDs(story, shots)
          const ordered_shots_defined = getOrderedShots(story, shots)

          // PRINT OUT TO SEE VALID TAKES
          // console.log('take_seq:', take_id_seq_defined.map(take_id  => takes.filter(take => take.id===take_id)[0]));

          // PREP LOW RES FILES
          let filtered_takes = take_id_seq_defined.map(take_id  => takes.filter(take => take.id===take_id));
          if(filtered_takes.length && filtered_takes[0].length) {
            const valid_take_urls = filtered_takes.map(take => (take.length) ? take[0].stream_path : ''); 
            const valid_take_urls_defined = valid_take_urls.filter(url  => (typeof url !== 'undefined') && (url !== null)); 
            const low_res_video_filenames = valid_take_urls_defined.map(url => url.substring(url.lastIndexOf("/p/")+1));


            // PREP HIGH RES FILES
            //console.dir(filtered_takes);
            //console.log('---high-res')
            const video_filenames = filtered_takes.map(take => (take.length) ? take[0].video_path : ''); 
//console.dir(video_filenames)
//console.dir(valid_take_urls)
//console.log('----videofilenames')
            // PREP TGT FILES
            const tgt_video_filenames = ordered_shots_defined.map(shot => shot.tgt_stream_url); 

            // TODO: PREP F_HASH to reconstruct files correctly
            // //const f_hash = take_id_seq_defined.map(take_id  => takes.filter(take => take.id===take_id)[0].f_hash);  // to reconstruct
            return {'files': video_filenames, 'proxy': low_res_video_filenames, 'tgt': tgt_video_filenames, 'url': valid_take_urls}
          } else {
            return {}    
          }

      } else {
        return {}
      }
    }