import React, {useState, useRef } from "react";
import ReactPlayer from "react-player" 
import config from '../utils/config'
import { Box } from '@mui/material';
import e from "cors";

const VIDEOS = [
  'https://www.youtube.com/watch?v=C0DPdy98e4c',
  'https://www.youtube.com/watch?v=Mxesac55Puo'
]

const last_take_stream_url = "https://storage.googleapis.com/v0zebra-dev.appspot.com/p/st/2EIOnw7CX3kSRUROpD3V/sh/XIK53BUHTq0KyuBoO8pM8/ta/765f7064-b067-40fe-bc6e-82901d872d52/q/2022-04-26T18_23_25_556Z-1.mp4"

const outro_url = config.storage_api + '/resource/vid/outro_1080.m4v'

function ZebraPlayer(props) {

  const video_urls = props.use_outro ? [ ...props.video_urls, outro_url] : props.video_urls;
  const onPlay = props.onPlay ? props.onPlay : () => {}
  const onPause = props.onPause ? props.onPause : () => {}
  const onEnded = props.onEnded ? props.onEnded : () => {}
  const volume = props.volume ? props.volume : 0
  const num_clips = video_urls.length
  const rPlayer = useRef(null);
  const [index, setPlayIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  //const [ playState, setPlayState ] = useState(false);

  // good-enough-for-now buffering 
  // so that the next clip can load while the current plays
  const [lookAhead, setLookAhead] = useState(true); 

  const lookAheadindex = (index < (num_clips - 1)) ? index + 1 : 0

  const nextVideo = () => {
    if (index < (num_clips - 1)){
    	setPlayIndex(index + 1)
    }
    else {
      onEnded();
      setPlayIndex(0)
      //rPlayer.current?.seekTo(0)
      setPlaying(false)
    }
  }

  const startVideo = () => {
    setPlaying(true)
  }

  const stopVideo = () => {
    setPlaying(false)
  }

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const readyVideo = () => {
    if(props.playing) startVideo()
  }

  //if(playing) setPlaying(true)
  if(props.playing && !playing) setPlaying(true)
  else if(!props.playing && playing) setPlaying(false)

  return (
    <Box sx={{ paddingTop:'56.25%', position:'relative'}}>
      <Box sx={{ position:'absolute', top:0, width:'100%', height:'100%' }}>
        <ReactPlayer   
          ref={rPlayer}       
          url={video_urls[index]}
          className='react_player'
          controls={props.controls}
          allowFullScreen={false}
          light={props.light}
          playsinline={true}
          playIcon={<span></span>}
          playing={playing}
          onPlay={() => { startVideo(); onPlay() }}
          onPause={() => { stopVideo(); onPause() }}
          //muted
          volume={volume}
          width='100%'
          height='100%'
          loop={props.loop != undefined ? props.loop : true}
          onEnded={nextVideo}
          onReady={readyVideo}
          onStart={startVideo}
          //onClick={startVideo}
          /*onMouseOver={startVideo}
          onHover={startVideo}
          onMouseOut={stopVideo}*/
        />
        {lookAhead && <Box sx={{ position:'absolute', top:'0', zIndex:'-1' }}><ReactPlayer
          url={video_urls[lookAheadindex]}
          className='react_player'
          controls={false}
          allowFullScreen={false}
          light={false}
          volume={0}
          width='0%'
          height='1%'
          playIcon={false}
          playing={false}
          onEnded={nextVideo}
          onStart={startVideo}
        /></Box>}
      </Box>
    </Box>
  )
}


export default ZebraPlayer