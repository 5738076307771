import React, { useContext, useEffect, useState } from "react";
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box, Icon } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
// import "../components/pmm.css"


function ImageSelector(props) {
    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const location = useLocation();

    // if (location.state) == undefined {
    //      // todo:  handle a direct landing on this page, which lacks a location history, by sending back to the main app.
    // }

    const shot = location.state.shot
    const take_url_list = location.state.take_url_list 
    const take_id_list = location.state.take_id_list 
    const take_stream_path_list = location.state.take_stream_path_list 

    const select_take =  take_url_list && take_url_list.length > 0

    function cancelSelection() {
        history.goBack();
    }

    function selectItem(item) {
        if (select_take) {
            //const shot_last_take_thumb_payload = {shot_id: shot.id, image: item.url}
            //dispatch({ type: "UPDATE_SHOT_SELECTED_TAKE_IM", payload: shot_last_take_thumb_payload }); 

            const take_id = item.id
            const shot_selected_take_payload = {shot_id: shot.id, take_id: take_id, image: item.url, stream_path: item.stream_path}
            dispatch({ type: "UPDATE_SHOT_SELECTED_TAKE", payload: shot_selected_take_payload }); 
        } else {
            const shot_im_payload = {shot_id: shot.id, image: item.url}
            dispatch({ type: "UPDATE_STORY_SHOT_IMAGE", payload: shot_im_payload });
        }
        history.goBack();
    }

    function getItemList() {
      
      if (select_take){
        // console.log("take_url_list", take_url_list);
        // console.log("take_id_list", take_id_list);
        // assert(take_url_list.length === take_id_list.length)

        const items = take_url_list.map(function(url, i) {
           return {'url':url, 'id':take_id_list[i], 'stream_path':take_stream_path_list[i]};
        });
        return items
      } else {
        const inds = [...Array(10).keys()]
        return inds.map(ind => ({url:`https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb00${ind}a.jpg`}))
        //return inds.map(ind => `https://storage.googleapis.com/v333m-clip/tgt/happybirthday2/th/thmb0${ind}a.jpg`)
  
      }

    }

    const item_list = getItemList()

    return( 
      <div className="ImageSelector">
        <h1>Select { select_take ? 'a take' :'an image' } </h1>  
        {shot.header}          
        <Grid container columnSpacing={({xs:1, md:1})}>
          <Grid xs={8} md={8}>
            {item_list.map((item, key) => (
              <Item key={key}>
                <Box> 
                <Box component="img" src={item.url} fluid="true" onClick={() => selectItem(item)} />
                </Box>
              </Item>
            ))}
          </Grid>
        </Grid>
        <br /> 
        <div className="btn-group">
          <button type="button" className="btn toggle-image-display" onClick={cancelSelection}> Cancel </button>
        </div>
      </div>
    )
}

export default ImageSelector