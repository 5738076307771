import { 
    Link, 
    Typography, 
    Container, 
    Box, 
    Button, 
  } from '@mui/material';
  import React, { useContext, useEffect, useState } from "react";
  import AppContext from "../utils/app-context";
  import { AuthContext } from "../utils/auth-provider";
  import theme from '../styles/theme'
  import Headbar from "./Headbar";
  import Footbar from "./Footbar";
  import { products, checkout } from "../utils/stripe";

  const credStyles = {
    color:theme.palette.primary.dark,
    fontSize:'24px',
    marginBottom:'50px',
  }
  
  function Credits() {
    const { state, dispatch } = useContext(AppContext);
    const { currentUser } = useContext(AuthContext);
    const [productList, setProductList] = useState([]);
    const [quantity, setQuantity] = useState(1);

    const handleIncrement = () => {
      setQuantity(quantity + 1);
    };

    const handleDecrement = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };
    
    const wrapStyles = {
      padding: "3em",
      maxWidth: "600px", 
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "1.5em",
      },
    };

    const incrementStyles = {
      padding:0, border:"none", boxShadow:0 
    };

    const purchase = (product) => {
      //alert('select')
      console.dir("price 123", product.prices[0].unit_amount)
      console.dir(product)
      if(product) {
        checkout(currentUser, product, quantity).then(url => {
          console.dir(url)
          window.location.assign(url)
        })
      }
    }

    const formatPrice = (num) => {
      num = (num/100).toFixed(2)
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(num);
    }

    useEffect(() => {
      products().then(items => {
        console.dir("items", items)
        //setProductList(items.filter(item => ["prod_QNoURuyI8jv3xO"].find(id => id == item.id)));
        setProductList(items);
        console.dir("inner list", productList)
      });
    }, []);
    
    
    return (
      <Container style={{ maxWidth:'100%', paddingLeft:0, paddingRight:0 }}>
        <Headbar title="Credits" settings={true} admin={true} />
        <Box sx={wrapStyles}>
          <Typography type="h2" sx={ credStyles }>Current Credits: {state.userData.credits}</Typography>
          { productList.map((product) => (
          <>
            {console.dir("product", product)}
            <Typography type="h2">{ product.name }</Typography>
            <Box sx={{ marginBottom:"10px" }}>{ product.description }</Box>
            <Box sx={{ marginBottom:"25px" }}>{ formatPrice(product.prices[0].unit_amount) }</Box>
            <Box sx={{ display:'flex', marginBottom:"40px" }}>
              <Button sx={ incrementStyles } onClick={handleDecrement}>-</Button>
              <Box sx={{ padding:"0 10px" }}>{quantity}</Box>
              <Button sx= { incrementStyles } onClick={handleIncrement}>+</Button>
            </Box>
            <Button sx={{ marginBottom:"80px" }} onClick={ (e) => purchase(product) }>Purchase</Button>
            {/*<Box sx={{ background:theme.palette.primary.dark, padding:"20px" }}>
              <Typography type="h2" sx={{ fontWeight:"bold", color:theme.palette.primary.contrastText }}>Subscribe &amp; Save</Typography>
              <Typography type="p" sx={{ color:theme.palette.primary.contrastText, marginBottom:"10px" }}>Pick from our popular Creator or Team plans and save on all credit purchases!</Typography>
              <Link href="/account/plan" onClick={ (e) => { history.push(e.target.getAttribute('href')); e.preventDefault(); }} sx={{ color:theme.palette.primary.contrastText, textDecoration:"underline", " &:hover": { textDecoration:"none" } }}>See Plans</Link>
            </Box>*/}
          </>
          ))
        }
        </Box>
      </Container>
    );
  }
  
  export default Credits;