import { nanoid } from "nanoid";
import { v4 as uuidv4 } from "uuid";
import assets from "../utils/assets";

export function formatDate (date, format) {
  let dateStr;
  if(format == 'title') {
    dateStr = date.getHours() + ':' + ((date.getMinutes() < 10) ? '0'+date.getMinutes() : date.getMinutes()) + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getDate() + ', `' + date.getFullYear().toString().slice(2)
  }

  return dateStr;
}

export const sortTakes = (takes) => {
  return takes.sort((a, b) => {
    let timeA = a.modified_at || a.uploaded_at;
    let timeB = b.modified_at || b.uploaded_at;
    if (timeA && !timeB) return 1;
    if (!timeA && timeB) return -1;
    if (!timeA && !timeB) return 1;
    return timeB.seconds - timeA.seconds;
  });
};

export const emptyStory = (portal_id, include_shot) => {
  const created = new Date();
  const first_shot = uuidv4();
  const includeShot = (include_shot == false) ? false : true;
  console.dir("EMPTY STORY", includeShot)
  console.dir("STORYBOARD", 
    {
      parts: [{ title: "Storyboard", order: includeShot ? [first_shot] : [] }],
      last_cut: null,
      title: formatDate(created, "title"),
      portal_id: portal_id || nanoid(12),
      created_at: created,
      deleted_at: null, 
      shots: includeShot ? [{
        header: "Shot 1",
        description: "HERO starts JOURNEY",
        meta: null,
        // here
        image: assets["empty_thumbnail"],
        // image:
        //   "https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/wide.png",
        id: first_shot,
        deleted_at: null,
      }] : [],
    })

  return {
    parts: [{ title: "Storyboard", order: includeShot ? [first_shot] : [] }],
    last_cut: null,
    title: formatDate(created, "title"),
    portal_id: portal_id || nanoid(12),
    created_at: created,
    deleted_at: null, 
    shots: includeShot ? [{
      header: "Shot 1",
      description: "HERO starts JOURNEY",
      meta: null,
      // here
      image: assets["empty_thumbnail"],
      // image:
      //   "https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/wide.png",
      id: first_shot,
      deleted_at: null,
    }] : [],
  };
};
