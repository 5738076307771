import React, { useContext, useCallback } from 'react';
import {
  Button,
  Icon,
  Link,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useLocation } from 'react-router';
import { useQRCode } from 'next-qrcode';
import config from '../utils/config';
import toast from 'react-hot-toast';
import AppContext from '../utils/app-context';
import { useHistory } from 'react-router-dom';

const useStyles = () => ({
  qrCode: {
    border: '0px solid #eee',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '280px',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '20px',
    marginRight: 0,
    textAlign: 'left',
    width: '100%',
    maxWidth: '420px',
  },
  successStyles: {
    position: 'absolute',
    bottom: '-20px',
    left: '0px',
    fontSize: '9px',
    lineHeight: '12px',
    textTransform: 'lowercase',
    transition: '.5s all',
  }
});

const ShareDialog = (props) => {
  const classes = useStyles();
  const app_url = config.app_url;
  const loc = useLocation();
  const history = useHistory();
  const { open, toggleShare } = props;
  const link = app_url + loc.pathname;
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { Canvas } = useQRCode();
  const isStoryOwner =  state.app.userIsStoryOwner;

   
  const defaultSettings = {
    communityCanUpload: true,
    communityCanCamera: true,
    communityCanVeneer: true,
    communityCanShare: true,
    communityCanForkWithMedia: false
  };

   
  const settings = { ...defaultSettings, ...state.app.settings };

  const handleClose = () => {
    toggleShare(false);
  };

  const copyLink = (text) => {
    navigator.clipboard.writeText(text);
    setShowSuccess(true);
    
   toggleShare(false);
   toast.success('Link Copied');

  };

  const updateSetting = (new_settings) => {
    dispatch({ type: 'UPDATE_STORY_SETTINGS', payload: { settings: new_settings } });

    /*if (state.stories.activeStory && state.stories.activeStory.ref) {
      const settingsRef = state.stories.activeStory.ref.collection('settings').doc('0');
      settingsRef.set(new_settings, { merge: true })
        
    } else {
    }*/
  };

  const handleCheckboxChange = useCallback(
    (event) => {
      const { name, checked } = event.target;
      let newSettings = { ...settings };

      switch (name) {
        case 'addGenerateMedia':
          newSettings.communityCanUpload = checked;
          newSettings.communityCanCamera = checked;
          newSettings.communityCanVeneer = checked;
          break;
        case 'shareTheStory':
          newSettings.communityCanShare = checked;
          break;
        case 'forkTheStory':
          newSettings.communityCanForkWithMedia = checked;
          break;
        default:
          break;
      }
      updateSetting(newSettings);
    },
    [settings, dispatch]
  );
  const handleMediaAction = () => {
    if (settings.communityCanUpload || settings.communityCanCamera) {
      toast.error('Media upload or camera access is disabled.');
      return;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          <span>Share</span>
          <Icon name="close" onClick={handleClose} aria-label="Close dialog" />
        </DialogTitle>
        <DialogContent
          sx={{ maxWidth: 700, maxHeight: '90vh', overflowY: 'hidden' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isStoryOwner ? { xs: 'column', sm: 'row' }: { xs: 'column' },
              alignItems: 'center',
              textAlign: { xs: 'center', sm: 'left' },
              '@media (max-width: 650px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              className={classes.qrCode}
              sx={{
                margin: '0 auto',
                mb: { xs: '16px', sm: '20px' },
              }}
            >
              <Canvas
                text={link}
                options={{
                  type: 'image/jpeg',
                  quality: 0.3,
                  level: 'H',
                  margin: 3,
                  scale: 4,
                  width: 280,
                }}
              />
            </Box>

            <Box
              className={classes.checkboxContainer}
              sx={{
                marginTop: { xs: 0, sm: 0 },
                textAlign: { xs: 'left', sm: 'left' },
                '@media (max-width: 650px)': {
                  marginLeft: 0,
                },
              }}
            >
              {isStoryOwner && (
                <>
                <DialogContentText
                id="alert-dialog-description"
                sx={{ fontWeight: 'bold', fontSize: { xs: '18px', sm: '20px' }, marginTop: '0px' }}
              >
                Anyone with this link can:
              </DialogContentText>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="addGenerateMedia"
                      checked={settings.communityCanUpload || settings.communityCanCamera || settings.communityCanVeneer}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Add / Generate Media"
                /><br/>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="forkTheStory"
                      checked={settings.communityCanForkWithMedia}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Fork The Story"
                /><br/>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="shareTheStory"
                      checked={settings.communityCanShare}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Share The Story"
                />
              </Box>
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Button
                  onClick={() => copyLink(link)}
                  sx={{
                    marginTop: '10px',
                    display: { xs: 'block', sm: 'inline-block' },
                  }}
                >
                  COPY LINK
                </Button>
              </Box>
              {showSuccess && (
                <Box className="copy-success" sx={classes.successStyles}>
                  Link Copied!
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ShareDialog;